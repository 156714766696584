import React from 'react';
import { useNavigate } from 'react-router-dom';

const MobileHeader = (props) => {
    const { logo, userMenu } = props;
    const navigate = useNavigate();

    const handleNavigation = (link) => {
        navigate(link);
        const navbar = document.getElementById('navbarSupportedContent');
        if (navbar) {
            navbar.classList.remove('show');
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: "#3a3633" }}>
            <div className="container-fluid" style={{ padding: '5px' }}>
                <img style={{ width: '40%' }} src={logo} alt="Logo" className="navbar-brand" />
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        {userMenu?.length > 0 ? (
                            userMenu.map((item) => (
                                <li className="nav-item" key={item.modulesid}>
                                    <a
                                        className="nav-link"
                                        aria-current="page"
                                        onClick={() => handleNavigation(item.link)}
                                        style={{ cursor: 'pointer', color: 'white' }}
                                    >
                                        {item.name}
                                    </a>
                                </li>
                            ))
                        ) : (
                            <li className="nav-item">
                                <span className="nav-link">No menu items available</span>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default MobileHeader;
