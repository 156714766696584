/* eslint-disable */
import React, { useEffect, useState } from 'react';
import logo from '../../../img/automatbLogo.png';
import * as API from '../../../framework/API/api_digitalSign';
import DigitalSignSignUpForm from './DigitalSignSignupForm';
import { useTranslation } from '../../../locale/useTranslation';
import { LOGIN_WARNING_MESSAGE } from '../../../framework/constant/constant';
import { ToastContainer, toast } from 'react-toastify';
import image from '../../../img/The-Power-of-Billboard-Advertising-1.jpg';
import { useNavigate } from 'react-router-dom';

const DigitalSignLoginForm = (props) => {

    const lang = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const navigate = useNavigate();

    const labelStyle = {
        color: '#7b838a',
        textTransform: 'uppercase',
        letterSpacing: '.1rem'
    };

    const handleLogin = (e) => {
        let event = {
            ...e,
            target: {
                username: { value: email },
                password: { value: password },
            }
        };
        props.handleLoginClick(event);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleLogin(event);
        }
    };

    const handleTogglePassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        if (email == undefined || email == "" || !emailRegex.test(email)) {
            toast.error(lang.please_enter_email_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        let res = await API.forgotPassword(email);
        console.log(res);
        if (res.success) {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setShowForgotPassword(false);
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    return (
        <>
            <div className="container-fluid vh-100 d-flex digital-sign-login-container">
                <div
                    className="position-absolute w-100 h-100 digital-sign-login-background"
                    style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        filter: 'blur(10px)',
                        zIndex: -1,
                        position: 'relative',
                    }}
                ></div>
                <div className="d-flex justify-content-end align-items-center w-100 text-center digital-sign-login-heading">
                    <div>
                        Manage Your Digital Signage Efficiently, Anytime & from Anywhere
                    </div>
                </div>
                <div className="d-flex justify-content-end align-items-center w-100 h-100 digital-sign-login-content">
                    <div className="col-auto d-flex flex-column justify-content-end align-items-start p-5 bg-white digital-sign-login-form" style={{borderRadius: '5px'}}>
                        <img src={logo} alt="Logo" className="mb-4" style={{ width: '200px' }} />
                        {!showForgotPassword
                            ? <>
                                <h2 style={{ fontSize: '3rem', marginBottom: '20px', lineHeight: 1, fontWeight: 600, letterSpacing: '.1rem' }}>{lang.sign_in_label}</h2>
                                <div className="mb-3">
                                    <label htmlFor="email" style={labelStyle}>{lang.email_label}</label><br/>
                                    <input
                                        style={{ width: "300px", color: '#495057', height: '3rem', display: 'inline-block', padding: "10px 20px" }}
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        placeholder="Type email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" style={labelStyle}>{lang.password_label}</label><br/>
                                    <input
                                        style={{ width: "300px", color: '#495057', height: '3rem', display: 'inline-block', padding: "10px 20px" }}
                                        type={showPassword ? 'text' : 'password'}
                                        className="form-control"
                                        name="password"
                                        placeholder="Type password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        required
                                    />
                                    <i style={{ position: "absolute", marginLeft: '-8.2%', marginTop: '17px', cursor: "pointer" }} className={`icon-append fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={handleTogglePassword} />
                                </div>
                                <div className="mb-3">
                                    <a onClick={(e) => { e.preventDefault(); setShowForgotPassword(true); }} className="text-primary cursor-pointer">{lang.forgot_password_label}</a>
                                </div>
                                {props?.showError && <div ng-show="error" className="alert alert-danger">
                                    <a href="# " className="close" data-dismiss="alert"><span></span></a>{LOGIN_WARNING_MESSAGE}
                                </div>}
                                <button
                                    onClick={handleLogin}
                                    type="submit"
                                    style={{ backgroundColor: (email && password) ? '#275B89' : '#ACB5BD', color: 'white' }}
                                    disabled={(email && password) ? false : true}
                                    className="btn mb-3"
                                >
                                    {lang.login_label}
                                </button>
                                <p>{lang.do_not_have_an_account_label} <a onClick={()=> navigate('/signup')} className="text-primary cursor-pointer">{lang.sign_up_label}</a></p>
                            </> :
                            <>
                                <h2 style={{ fontSize: '2rem', marginBottom: '20px', lineHeight: 1, fontWeight: 600, letterSpacing: '.1rem' }}>{lang.forgot_your_password_label}</h2>
                                <p>{lang.enter_email_note}</p>
                                <div className="mb-3">
                                    <label htmlFor="email" style={labelStyle}>{lang.email_label}</label>
                                    <input
                                        style={{ width: "122%", color: '#495057', height: '3rem', display: 'inline-block' }}
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        placeholder="Type email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        required
                                    />
                                </div>
                                <button
                                    onClick={handleForgotPassword}
                                    type="submit"
                                    style={{ backgroundColor: email ? '#275B89' : '#ACB5BD', color: 'white' }}
                                    disabled={email ? false : true}
                                    className="btn mb-3"
                                >
                                    {lang.reset_password_button_text}
                                </button>
                                <p>{lang.remember_password_label} <a onClick={(e) => { e.preventDefault(); setShowForgotPassword(false); }} className="text-primary cursor-pointer">{lang.sign_in_label}</a></p>
                            </>}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default DigitalSignLoginForm;