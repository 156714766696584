/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as API from '../../framework/API/api'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ENTITYNAME, PUBLISHING_SOURCE, MEDIACATEGORIES, OTTROUTETYPE, ASSETTYPE, MODULE, LOGEVENT, LOCALSTORAGE_KEY, ACCESS_LEVEL, PLATFORMTYPE } from "../../framework/constant/constant";
import { CardList } from "../../components/CardList";
import { FixedSidebarWithIcons } from "../../components/FixedSidebarWithIcons";
import SeriesDetailForm from "./SeriesDetailForm";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SeasonMetaDataEditForm from "./SeasonMetaDataEditForm";
import { EditPageHeader } from "../../components/EditPageHeader";
import MediaEpisodePublishingEditForm from "./MediaEpisodePublishingEditForm";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import MediaEpisodeAssetEditForm from "./MediaEpisodeAssetEditForm";
import moment from "moment";
import IsShortMediaCollectionSelection from "../../framework/forms/IsShortMediaCollectionSelection";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmAlert, ConfirmDeleteAlert } from "../../ConfirmAlert";
import { DataHelper } from "../../framework/helper/DataHelper";
import { utility } from "../../framework/utility/utilityProvider";

export const SeriesEditForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const lang = useTranslation();
  const { SID } = useParams();
  const user = utility.getValue(LOCALSTORAGE_KEY.userData);

  let blankDataItem = {
    SID: SID,
    Title: '',
    Content: {},
    Description: '',
    ImageUrl: '',
    Synopsis: '',
    Archive: false,
    Posters: [],
    Languages: [],
    Keywords: '',
    PGRating: {},
    ImageUrl: 'https://media.comicbook.com/files/img/default-movie.png',
    SeasonNumber: 1,
    IsLive: false,
    Genre: [],
    SubGenre: [],
    
  }

 

  const [seriesEntity, setSeriesEntity] = useState(blankDataItem);

  const [season, setSeason] = useState([]);
  const [tabNumber, setTabNumber] = React.useState("1");
  
  const isAssestID = utility.isNumeric(SID) == false;
  const isEdit = isAssestID || SID > 0 ? true : false;
  const [openSeason, setOpenSeason] = useState(isEdit);
  const [isLoadedEdit, setIsLoadedEdit] = useState(false);
  const [mediaEpisodeEntity, setMediaEpisodeEntity] = useState({});
  const [seriesPublishings, setSeriesPublishings] = useState([]);
  const [mediaCategory, setMediaCategory] = useState([]);
  const [removedMedia, setRemovedMedia] = useState([]);
  const [assetDataItem, setAssetDataItem] = useState({});

  const [createSeasonEpisode, setCreateSeasonEpisode] = useState([]);

  const [mediaOpen, setMediaOpen] = useState(false);
  const [isArchiveChanged, setIsArchiveChanged] = useState(false);
  const [selectedAttachedMedia, setSelectedAttachedMedia] = useState([]);

  const [imagePotraitImageFiles, setPotraitImageFiles] = useState(new Set());
  const [imageLandscapeImageFiles, setLandscapeImageFiles] = useState(new Set());

   //
   const [openPublishingDialog, setOpenPublishingDialog] = useState(false);
   const [defaultDealRight, setDefaultDealRight] = useState({});
   const [publishingDialogTitle ,setPublishingDialogTitle] = useState(null);

  const customeModelFields = {
    Title: "Title",
    Description: "Description",
    PosterURL: "ImageUrl",
    ThirdLine: "SeasonNumber"
  }

  useEffect(() => {
    loadcombo();
    if (isEdit) {
      loadEditData();
    } else {
      //in new it should be loaded without server fretching data
      setIsLoadedEdit(true);
    }


  }, [])

  const loadcombo = async () => {
    let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory);
    setMediaCategory(mediaCategory.data);
  }

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const loadEditData = async () => {

    let res = {message:"Not Valid", success:false};

    if(isAssestID){
       res = await API.getEntityfromAssestID(ENTITYNAME.Series, SID);
    }
    else
    {
       res = await API.getEntity(ENTITYNAME.Series, SID);
    }

    if(!res.success){
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let season = await API.getData(ENTITYNAME.Season, { query: ["Series_Id", "=", res.data._id] });
    console.log(season.data)

    if (season.success) {
      let seasonData = [...season.data].map((item) => { return { ...item, SeasonNumber: "Season: " + item.SeasonNumber } });
      setSeason(seasonData);
    }
   
      setSeriesEntity({ ...blankDataItem, ...res.data });
      setIsLoadedEdit(true);
      setMediaEpisodeEntity(res.data);

      //load asset details
    if(res.data.IsOttAsset){

      let assetRes = await API.getData(ENTITYNAME.OttAsset, { query: [['Source._id', '=', res.data._id], ['Source.entityName', '=', 'series']] });
      console.log(assetRes);
      if(assetRes.data && assetRes.data.length > 0){[
        setAssetDataItem({...assetRes.data[0],IsOttAsset : res.data.IsOttAsset})
      ]}
    }

      //load attached media
      let attachedMedia = await API.getData(ENTITYNAME.MediaEpisode, { query: ['Media._id', '=', res.data._id.toString()] });
      setSelectedAttachedMedia(attachedMedia.data);

      // fetching deal rights
      let mediaDealRights = await API.getData(ENTITYNAME.MediaDealRights, {
        query: ["media_id", "=", res.data._id.toString()],
      });
      let tempData = [];
      if (mediaDealRights.data.length > 0) {
        tempData = mediaDealRights.data.map((obj, index) => {
          return {
            ...obj,
            index: index
          }
        })
      } else {
        tempData = mediaDealRights.data
      }

      setSeriesPublishings(tempData ?? []);
   
  };

  const setMataData = (data) => {
    setSeriesEntity({ ...seriesEntity, ...data });
  }

  const createNewSeasonEpisode = async (seriesId,data) => {

    if (data.SID == 0 && createSeasonEpisode.length > 0) {
      let seasonData = {
        seriesId: seriesId,
        seasons: createSeasonEpisode,
      }

      console.log("seasonData");
      console.log(seasonData);

      //Creating Seasons and Episodes
      let response = await API.createSeriesSeasons(seasonData);

      console.log(response);

      if (!response.success) {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        return null;
      } else {

        //MAINTAING LOGS FOR NEW CREATED SEASON AND EPISODES from SERIES
        if (response?.success && response?.data?.MediaEpisode.length > 0) {
          response?.data?.MediaEpisode.forEach((obj) => {
            obj?.data.forEach((x) => {
              let logData = { event: LOGEVENT.CREATE_EPISODE, module: MODULE.EPISODE, data: x?.data, message: 'Create Episode' };
              API.SaveLogs(logData);
            })
          });
        }

        if (response?.success && response?.data?.Season?.length > 0) {
          response?.data?.Season?.forEach((obj) => {
            let logData = { event: LOGEVENT.CREATE_SERIES, module: MODULE.MEDIA_EPISODE_SEASON, data: obj?.data, message: 'Create Media Episode Series' };
            API.SaveLogs(logData);
          });
        }
        return response;
      }
    } 
     return null;

  }

  const saveArchiveChange = async () => {
    if (isArchiveChanged && seriesEntity.SID > 0) {
      console.log('------here------')
      await API.onChangeArchiveInSeriesSeason({
        _id: seriesEntity._id,
        archive: seriesEntity.Archive,
        entityName: ENTITYNAME.Series
      })
      setIsArchiveChanged(false);
    }
  }

  //SAVE AND UPDATE DEFAULT MEDIA DEAL RIGHTS
  const setDefaultDealRights = async () => {

    setPublishingDialogTitle("Please create deal rights first")
    handleTabChange({}, 2);
    setOpenPublishingDialog(true);

    //FETCH DEFAULT DEAL RIGHTS
    let resDefaultDealRights = await API.getData(ENTITYNAME.UserDefaultDealRights, { query: [["user_id", "=", user._id]] });
    if (resDefaultDealRights.success && resDefaultDealRights.data.length > 0) {

      setDefaultDealRight({
        ...resDefaultDealRights?.data[0],
        Publishing: {
          ...resDefaultDealRights?.data[0].Publishing,
          PublishingSource: PUBLISHING_SOURCE.Series
        }
      });
    } else {
      return;
    }
  }
  
    const updateDefaultDealRights = async () => {
  
      let newDefaultDealRight = seriesPublishings.find((x) => x.IsDefault);
  
      //FETCH DEFAULT DEAL RIGHTS
      if(newDefaultDealRight){
  
        delete newDefaultDealRight.IsDefault;
  
        let updateData = {
          user_id: user._id,
          Publishing : newDefaultDealRight 
        }
  
        if(Object.keys(defaultDealRight).length > 0) updateData._id = defaultDealRight._id;
  
        await API.saveData(ENTITYNAME.UserDefaultDealRights, updateData);
  
      }
  
    }


  const onSave = async () => {

    let localSeriesEntity = { ...seriesEntity };

    const series_id = localSeriesEntity._id;

    if (localSeriesEntity.SID == 0) {
      const updatedContent = await API.getAndSaveNextNumber(
        localSeriesEntity.Content.SID,
        { ...localSeriesEntity.Content, isSaveNextnumber: true }
      );

      if(!updatedContent.success) {
        toast.error(updatedContent.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }

      localSeriesEntity.AssetId = updatedContent.data.HouseNumber;
    }

    let data = DataHelper.saveSeriesEntity({ ...localSeriesEntity, SID: parseInt(localSeriesEntity.SID) }, mediaCategory, assetDataItem.IsOttAsset, localSeriesEntity?.ImageUrl);  

    if (data?._id?.toString()?.length > 0) {
      delete data?._id
    }

    if(assetDataItem.IsOttAsset && !isValidAsset()){
      return;
    }
    
    //PUT MEDIA RIGHTS VALIDATION HERE
    // if(user && user?.Channels?.length > 1 && seriesPublishings.length == 0){
    //   toast.error(`Please create deal rights for atleast one channel`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return;
    // }

    if(user && user?.Channels?.length > 1 && seriesPublishings?.filter(x => x?.PlatformType?.SID == PLATFORMTYPE[0].SID)?.length == 0){
      await setDefaultDealRights();
      return;
    }

    if(!isEdit){
      await updateDefaultDealRights();
    }

    let res = await API.saveData(ENTITYNAME.Series, data);

    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {

      //CREATE NEW SEASON AND EPISODES
      let newSeasonEpisodeRes = await createNewSeasonEpisode(res.data._id,data);
      if(newSeasonEpisodeRes) handleSetSeriesEntity(res.data, newSeasonEpisodeRes?.data?.Season);

      // Update season episode count
      await API.updateSeasonEpisodeCount({
        data: res.data, //series saved entity
        entityName: ENTITYNAME.Series
      });

      //ARCHIVE DATA FOR WHOLE SERIES 
      await saveArchiveChange();
      
      // ATTACH MEDIA TAB SAVE 
      await saveMediaInIsShorts();

      //PUBLISHING TAB SAVE OR CREATING DEAFAULT rights

      console.log(seriesPublishings);
      console.log(seriesPublishings.filter(x => x.PublishingSource == PUBLISHING_SOURCE.Series));


      let response = await API.updateMediaDealRights(
        res.data, 
        removedMedia, 
        seriesPublishings.filter(x => x.PublishingSource == PUBLISHING_SOURCE.Series), 
        PUBLISHING_SOURCE.Series,
        seriesPublishings.length == 0
      );

      //OTT ASSET TAB SAVE
      let assetsData = {};
      if (assetDataItem.IsOttAsset) {
        let assetRes = await onAssetSubmit({ ...res.data, _id: series_id });
        if(assetRes.success){
          assetsData = assetRes.data
        }
      }

      let logData = { 
        event: localSeriesEntity.SID == 0 ? LOGEVENT.CREATE_PARENT_SERIES : LOGEVENT.UPDATE_PARENT_SERIES, module: MODULE.MEDIA_EPISODE_SERIES, 
        data: { ...res.data, MediaDealRights: response?.data, _id: series_id, AttchMedia: selectedAttachedMedia, Assets: assetsData }, 
        message: res.message };
      API.SaveLogs(logData);

      toast.success("Saved Successfully", {
        position: toast.POSITION.TOP_RIGHT
      });

      if(props.quickEdit){
        navigate(location.state.prevPath);
      } else {
        let findIndex = location?.state?.prevPath.indexOf("?");
        let pagination = location?.state?.prevPath?.slice(findIndex, location?.state?.prevPath?.length) ?? "";
        let state = {
          quickEdit: false,
          prevPath: location?.state?.prevPath ?? "/home/MediaEpisode/Series", 
          pagination: location?.state.pagination ?? pagination,
          paths: location.state.paths
        }
        navigate(`/home/MediaEpisode/Series/${res.data.SID}`, { state: state });
      }
      
      setOpenSeason(true);
    }
  }

  const clear = () => {
    setSeriesEntity(blankDataItem);
  }



  const handleSetSeriesEntity = (data, seasonData) => {

    setSeriesEntity(data);

    let tempSeasonData = [];
    if (seasonData.length > 0) {
      seasonData.map((obj) => {
        tempSeasonData.push(obj.data);
      })
      setSeason(tempSeasonData);
    }

  }

  const handleSeriesPublishings = (data) => {
    setSeriesPublishings(data);
    setPublishingDialogTitle(null);
  }

  const handleRemovePublishingData = (removedDataItem) => {
    setRemovedMedia(old => [...old, removedDataItem]);
  }


  const deleteSeason = async (index, dataItem) => {
    console.log(dataItem);

    setSeason((old) => old.filter((obj) => obj.SID != dataItem.SID));
    let res = await API.saveData(ENTITYNAME.Season, {
      ...dataItem,
      Series_Id: ''
    });
    if (!res.success) return;

  }

  const onSeasonEdit = (index, dataItem) => {
    console.log(dataItem);
    let findIndex = location?.state?.prevPath.indexOf("?");
    let state = {
      quickEdit: false, 
      prevPath: location.pathname, 
      pagination: location?.state?.prevPath?.slice(findIndex, location?.state?.prevPath?.length) ?? "",
      paths: [...location.state.paths, location.pathname]
    }
    navigate(`/home/MediaEpisode/Season/${dataItem.SID}`, { state: state });
  }

  const getSeriesMenu = (index, dataItem) => {
    return [
      {
        label: `${lang.options_actionbutton_menutext}`,
        items: [
          { label: `${lang.delete_actionbutton_option}`, icon: 'pi pi-fw pi-trash', command: () => { } },
          { label: `${lang.moveup_actionbutton_option}`, icon: 'pi pi-fw pi-arrow-up ', command: () => { } },
          { label: `${lang.movedown_actionbutton_option}`, icon: 'pi pi-fw pi-arrow-down', command: () => { } }
        ]
      }
    ]
  }

  const sideFixMenu = [
    {
      icon: 'play',
      title: `${lang.sidefix_season_tooltip}`,
      onClick: () => { setOpenSeason(old => !old) }
    },
  ]

  //asset
  const setAssetEntity = (entity) => {
    setAssetDataItem(entity)
  }

  function isValidAsset() {

    if(!assetDataItem.VodTypes || Object.keys(assetDataItem.VodTypes).length == 0 ){
      toast.error(lang.please_select_vod_type_validation_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if ((assetDataItem.routeType == OTTROUTETYPE.Page || assetDataItem.routeType.Description == 'Page') && (assetDataItem.page_id == "" || assetDataItem.page_id == undefined)) {
      toast.error(lang.please_select_page, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if(assetDataItem.Videos.length == 0){
      toast.error(lang.please_attach_atleast_one_video_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if(assetDataItem.Posters.length == 0){
      toast.error(lang.please_attach_atleast_one_poster_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }

  const onAssetSubmit = async (seriesEntity) => {

      const saveData = {
        Title: seriesEntity.Title,
        Description: seriesEntity.Title,
        TBA: assetDataItem.TBA ?? false,
        StartDate: assetDataItem.StartDate ? new Date(assetDataItem.StartDate).getTime() : new Date(moment(new Date()).format('YYYY-MM-DD')).getTime(),
        EndDate: assetDataItem.EndDate ? new Date(assetDataItem.EndDate).getTime() : new Date(moment(new Date()).add(1, 'M').format('YYYY-MM-DD')).getTime(),
        OttAssetTypeSID: ASSETTYPE['Media Asset'],
        Archive: assetDataItem.Archive ?? false,
        Source: { _id: seriesEntity._id, SID: seriesEntity.SID, Title: seriesEntity.Title, MediaCategory: seriesEntity.MediaCategory, entityName: 'series' },
        routeType: typeof assetDataItem.routeType === 'object' ? assetDataItem.routeType.ID : assetDataItem.routeType,
        page_id: typeof assetDataItem.page_id === 'object' ? assetDataItem.page_id._id : assetDataItem.page_id,
        VodTypes: {
          _id: assetDataItem.VodTypes._id,
          SID: assetDataItem.VodTypes.SID,
          Name: assetDataItem.VodTypes.Name
        },
        Provider: assetDataItem.Provider ? (typeof assetDataItem.Provider == "string") ? assetDataItem.Provider : assetDataItem.Provider?._id : "",
        Videos: assetDataItem.Videos ?? [],
        Posters: assetDataItem.Posters ?? [],
        RentalRetailPrice : assetDataItem.VodTypes.Name == 'TVOD' ? parseFloat(assetDataItem.RentalRetailPrice) : 0,
        RentalWholesalePrice :assetDataItem.VodTypes.Name == 'TVOD' ? parseFloat(assetDataItem.RentalWholesalePrice) : 0,
        WebAssets: assetDataItem?.WebAssets ? assetDataItem?.WebAssets?._id : "",
        accessLevel: assetDataItem?.accessLevel?._id ?? ACCESS_LEVEL.Both,
        Segments: (assetDataItem?.Segments && assetDataItem?.Segments?.length > 0) ? assetDataItem?.Segments?.map((x) => {
          return {
            _id: x?._id,
            Description: x?.Description
          }
        } ) : []
      }

      if (assetDataItem._id != undefined) {
        saveData._id = assetDataItem._id;
        saveData.SID = assetDataItem.SID
      }

      console.log(saveData);

      let res = await API.saveData(ENTITYNAME.OttAsset, saveData);

      let logData = { event: res.message == "inserted" ? LOGEVENT.CREATE_ASSETS : LOGEVENT.UPDATE_ASSETS, module: MODULE.OTT_ASSETS, data: res.data, message: res.message };
      API.SaveLogs(logData);
      return res;
  };
  console.log(location.state);
  const onCancel = () => {

    console.log(seriesEntity);
    console.log(location.state)

    if(seriesEntity.SID == 0){

      ConfirmAlert(() => {
        if(location.state && location.state.prevPath){
          navigate(location.state.prevPath)
        } else {
          navigate(-1) ? navigate(-1) : window.close(); 
        }
       }, () => { return; }, "Discard changes?", "Are you sure you want to discard changes?")
    } else {
      if(location.state && location.state.prevPath){
        let state = {
          ...location.state,
          pagination: location.state.pagination ?? "",
          paths: location.state.paths.slice(0, -1)
        }
        navigate(location.state.paths.length == 1 ? location.state.paths.pop() + location.state.pagination ?? "" : location.state.paths.pop(), { state: state })
      } else {
        navigate(-1) ? navigate(-1) : window.close(); 
      }
    }


  }


  const handleMediaSelect = (dataList) => {
    //CHECK DUPLICATE 

    let duplicate = [];
    let toAdd = [];

    if (dataList.length > 0) {
      dataList.map((x) => {
        if (selectedAttachedMedia.some(y => y.SID == x.SID)) {
          duplicate.push(x.Title);
        } else {
          toAdd.push(x);
        }
      })
    }

    if (duplicate.length > 0) {
      toast.info(`${duplicate.join(',')} are already exists`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    setSelectedAttachedMedia([...selectedAttachedMedia, ...toAdd]);


  }

  const saveMediaInIsShorts = async () => {

    // console.log(selectedAttachedMedia)
    if (selectedAttachedMedia.length == 0) return;

    for (const element of selectedAttachedMedia) {
      let item = element;

      let attachedMedia = {
        _id: seriesEntity._id,
        SID: SID,
        Title: seriesEntity.Title,
        MediaCategory: seriesEntity?.MediaCategory && Object.keys(seriesEntity?.MediaCategory).length > 0 ? {
          _id: seriesEntity?.MediaCategory?._id,
          SID: seriesEntity?.MediaCategory?.SID,
          Description: seriesEntity?.MediaCategory?.Description,
          isLong: seriesEntity?.MediaCategory?.isLong
        } : {},
        entityName: 'series'
      }

      let response = await API.saveData(ENTITYNAME.MediaEpisode, { _id: item._id, Media: attachedMedia });

      if (!response.success) {
        console.log(response)
      }

    }

  }

  const MyAttachMediaCommandCell = (props) => (
    <div style={{ display: "flex" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteAttachedMedia(props.dataItem), () => { })} />
    </div>
  )

  const deleteAttachedMedia = async (dataItem) => {
    let filterMedia = selectedAttachedMedia.filter(x => x._id != dataItem._id);
    setSelectedAttachedMedia(filterMedia);

    let response = await API.saveData(ENTITYNAME.MediaEpisode, { _id: dataItem._id, Media: {} });
    if (!response.success) {
      console.log(response)
    }
  }

  return (
    <>
      <div className="myFlexContainer" >
     
        <FixedSidebarWithIcons menuModel={sideFixMenu} />
        {openSeason && <CardList MenuModal={getSeriesMenu}
          close={() => setOpenSeason(false)}
          containerName="Destination"
          customeModelFields={customeModelFields}
          title={lang.series_cardlist_header}
          onAddClick={() => navigate("/home/MediaEpisode/Season/0", { state: { quickEdit: false, selectedSeries: seriesEntity, disableSelectSeries: true, existingSeason: season } })}
          posterURL="https://play-lh.googleusercontent.com/LsmwRk16eEuZXxYLc-FGARu72Ji1qQq3Ow5d0aQ6tX2JE_yAFwRVx0Ubuv5rWuhNnSEa=w480-h960-rw"
          cardData={season}
          showSearch={true}
          onEditDataItem={onSeasonEdit}
          onDeleteDataItem={deleteSeason}
          inTileView={false}
          showTileView={true}
          showPreferenceButton={true}
          columnEntityName={'season'} />}
        <div className="main-layout" >
          <EditPageHeader title={`${lang.series_sub_menu} -> ` + (seriesEntity.Title ? seriesEntity.Title : `${lang.new_button_dialog_header}`)} onSubmit={onSave} onCancel={onCancel} />
          <SeriesDetailForm dataItem={seriesEntity} setDataItem={setSeriesEntity} setSeriesData={handleSetSeriesEntity} quickEdit={location?.state?.quickEdit??false}  setIsArchiveChanged={setIsArchiveChanged} isArchiveChanged ={isArchiveChanged} createSeasonEpisode={createSeasonEpisode} setCreateSeasonEpisode ={setCreateSeasonEpisode} onSave={onSave}/>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabNumber}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                  <Tab label={lang.meta_data_label} value="1" />
                  <Tab label={lang.publishing_label} value="2" />
                  <Tab label={lang.ott_assets_collection_header} value="3" />
                  <Tab label={lang.attach_media_button_tooltip} value="4" />
                </TabList>
              </Box>
              <TabPanel value={"1"}>
                {isLoadedEdit && <SeasonMetaDataEditForm dataItem={seriesEntity} setMataData={setMataData} />}
              </TabPanel>
              <TabPanel value={"2"}>
                <MediaEpisodePublishingEditForm mediaCategory={mediaCategory.find((obj) => obj.SID == MEDIACATEGORIES.Series)} mediaEpisodeEntity={mediaEpisodeEntity} publishingSource={PUBLISHING_SOURCE.Series} data={seriesPublishings} handlePublishings={handleSeriesPublishings} handleRemovePublishingData={handleRemovePublishingData} openPublishingDialog={openPublishingDialog} setOpenPublishingDialog={setOpenPublishingDialog} defaultDealRight = {defaultDealRight} isNewMedia={!isEdit} dialogtTitle={publishingDialogTitle}  />
              </TabPanel>
              <TabPanel value={"3"}>
                <div className="row mb-4" style={{ scrollbarWidth: "none", msOverflowStyle: 'none', overflow: 'auto', height: '63vh', boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "13px 0px 15px 5px" }}>
                  <MediaEpisodeAssetEditForm source={'series'} sourceEntity={seriesEntity} entityname={ENTITYNAME.OttAsset} dataItem={assetDataItem} setDataEntity={setAssetEntity} setPotraitImageFiles={setPotraitImageFiles} setLandscapeImageFiles={setLandscapeImageFiles} />
                </div>
              </TabPanel>
              <TabPanel value={"4"}>
                <div className="row" style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "30px 20px 30px 20px" }}>
                  <RoundButton icon={'link'} title={lang.attach_media_button_tooltip} onClick={() => setMediaOpen(true)} />
                  <Grid data={selectedAttachedMedia} style={{ height: "30vh" }}>
                    <GridColumn cell={MyAttachMediaCommandCell} width={"40px"} locked={true} />
                    <GridColumn field="AssetId" title={lang.assetId_column} editable={false} />
                    <GridColumn field="Title" title={lang.title_column} editable={false} />
                  </Grid>
                </div>
              </TabPanel>
            </TabContext>
          </Box>

        </div>
      </div>
      {mediaOpen && <IsShortMediaCollectionSelection addButtonTitle={lang.select_button_text} title={lang.media_library_dialog_header} wherestatement={['MediaCategory.isLong', '=', false]} setDataList={handleMediaSelect} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setMediaOpen(false)} width={"50vw"} height={"60vh"} />}
    </>)
};