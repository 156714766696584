import Menu from "./menu"
import model from "./menuModel";
import classNames from "classnames";
import { useTranslation } from "../../locale/useTranslation";
import * as API from "../../framework/API/api";
import { ENTITYNAME } from "../../framework/constant/constant";
import { utility } from '../../framework/utility/utilityProvider';
import { LOCALSTORAGE_KEY } from "../../framework/constant/constant";
import React, { useState, useEffect, useCallback } from "react";

const Navbar = (props) => {

    const lang = useTranslation();

    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    const [userMenus, setUserMenus] = useState([]);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to calculate screen orientation and size
        const updateScreenType = () => {
            const isPortrait = window.innerHeight > window.innerWidth;
            setIsMobile(isPortrait); // Set true for portrait mode
        };

        // Initial check
        updateScreenType();

        // Add event listener for window resize
        window.addEventListener('resize', updateScreenType);

        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', updateScreenType);
    }, []);

    const getUserFavouriteMenus = useCallback(async () => {

        const pageReloadCountData = utility.getValue('PageReloadCount');
        // console.log(pageReloadCountData);
        if (pageReloadCountData == undefined) {
            var menus = await API.getDataLookup(ENTITYNAME.UserFavouriteMenu, { query: ["User_id", "=", user._id] }, { sort: { Name: 1 } });
            console.log(menus.data);
            utility.setValue(LOCALSTORAGE_KEY.userfavouritemenu, menus.data);
            var PageReloadCount = 1;
            utility.setValue('PageReloadCount', PageReloadCount);
            // window.location.reload();
            setUserMenus(menus.data)
            return menus.data;
        }
    }, [])

    useEffect(() => {
        document.addEventListener("updateMenu", getUserFavouriteMenus);
        getUserFavouriteMenus();
        return () => {
            document.removeEventListener("updateMenu", getUserFavouriteMenus);
        }
    }, [userMenus])



    return (
        <>
            {isMobile ? <></> :
                <div className={classNames('left-menu', props.isMinified ? 'minified' : '')}>

                    <nav>
                        <ul>
                            <Menu menuModel={model(lang, userMenus, user)} isMinified={props.isMinified} />
                        </ul>
                    </nav>

                    <div onClick={props.onMinifyMenuClick}> <span className="minifyme"> <i className="fa fa-arrow-circle-left hit"></i> </span> </div>

                </div>}
        </>
    )
}

export default Navbar;