import Card from 'react-bootstrap/Card';
import { OTT_POSTER_TYPE } from '../../../constant/constant';
import { utility } from '../../../utility/utilityProvider';
import { useEffect, useState } from 'react';

export const ImageCell = (props) => {

  const [imageUrl, setImageUrl] = useState('https://media.comicbook.com/files/img/default-movie.png');
  const [value, setValue] = useState('');
  const field = props.field ?? "";
  const width = props.width ?? "50px";
  const height = props.height ?? "50px";
  const title = props?.title ?? "";

  useEffect(() => {
    const getFieldData = () => {
      if (field.includes('.')) {
        const keys = field.split('.');
        return props.dataItem[keys[0]]?.[keys[1]] ?? '';
      }
      return props.dataItem[field];
    };

    const tempValue = getFieldData();
    setValue(tempValue);
    const matchingKey = Object.keys(OTT_POSTER_TYPE).find(x => title?.includes(x));
    const url = utility.getPosterUrl(tempValue, OTT_POSTER_TYPE[matchingKey]);
    setImageUrl(url);
  }, [props.dataItem, field]);

  const imgStyle = { objectFit: "cover", height, borderRadius: "8px", width };

  const handleError = () => {
    setImageUrl('https://media.comicbook.com/files/img/default-movie.png');
  };

  return (
    <td className={props.className ?? ''} style={{ ...props.style, height }} title={value}>
      <Card.Img
        src={imageUrl}
        onError={handleError}
        style={{ ...imgStyle, objectFit: props.objectFit ?? imgStyle.objectFit }}
      />
    </td>
  );
};
