/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import * as API from "../../framework/API/api";
import { BILLBOARD_TYPES, ENTITYNAME, LOGEVENT, MEDIACATEGORIES, MEDIACATEGORYTYPES, MEDIATYPE, MEDIATYPEENUM, MODULE } from "../../framework/constant/constant";
import { Grid, GridColumn as Column, getSelectedState, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { utility } from "../../framework/utility/utilityProvider";
import { getter } from "@progress/kendo-data-query";
import ArchiveIconCell from "../../components/ArchiveIconCell";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat"
import BossDialog from "../../components/BossDialog";
import { DropDownCell } from "../Contract/ContractCommandCell";
import ContractCollectionSelection from "../Contract/ContractCollectionSelection";
import { Description } from "@mui/icons-material";

const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

function VariationEditForm(props) {

    const { SID } = useParams();

    let blankDataItem = {
        Name: "",
        Channel: {},
        MediaType: [],
        MediaEpisodes: [],
        MediaCategoryType: {},
        Archive: false,
        IsFetchFromLibrary: false
    }

    const lang = useTranslation();
    const navigate = useNavigate();

    const [channel, setChannel] = useState([]);
    const [mediaTypes, setMediaTypes] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [openCollectionMediaEpisode, setOpenCollectionMediaEpisode] = useState(false);
    const [openLongFormMediaCollection, setOpenLongFormMediaCollection] = useState(false);
    const [openParentMediaCollection, setOpenParentMediaCollection] = useState(false);
    const [selectedState, setSelectedState] = useState({});
    const [selectedExclusionState, setExclusionSelectedState] = useState({});
    const [searchText, setSearchText] = useState("");
    const [filterData, setFilterData] = useState([]);
    const [exclusions, setExclusions] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(lang.no_record_found_error_message);
    const [slotData, setSlotData] = useState("00:00:00:00");
    const [showSlotEditForm, setShowSlotEditForm] = useState(false);
    const selectedItemRef = useRef(null);
    const [editID, setEditID] = useState(null);
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [mediaCategoryTypes, setMediaCategoryTypes] = useState([]);
    const [collectionSelectionQuery, setCollectionSelectionQuery] = useState(['MediaCategory.isLong', '=', false]);

    useEffect(() => {
        loadcombo();
        if (SID > 0) {
            loadEditData();
        }
    }, []);

    const loadEditData = async () => {

        setLoadingStatus(lang.data_loading_error_message);

        let linkData = {
            link_collection: 'mediaepisode',
            link_field: 'MediaEpisodes'
        }
        let res = await API.getEntity(ENTITYNAME.Variation, parseInt(SID), linkData);
        if (res.data.length == 0) {
            res = await API.getEntity(ENTITYNAME.Variation, parseInt(SID));
        }
        if (res.success) {
            setDataItem(res.data);
            let mediaData = res?.data?.MediaEpisodes ?? [];
            if (mediaData.length > 0) {
                mediaData.map((x) => {
                    let slotDetail = res?.data?.SlotDetail?.find(y => y?.MediaEpisode_id == x?._id);
                    let billBoardDetail = res?.data?.BillBoardDetail?.find(y => y?.MediaEpisode_id == x?._id);

                    if (slotDetail) {
                        x.Slot = utility.convertMilisecondsToStringWithFrames(slotDetail.Slot);
                    } else {
                        x.Slot = "00:00:00:00";
                    }

                    if(billBoardDetail){
                        x.ParentMediaEpisode = billBoardDetail.ParentMediaEpisode
                        x.Type = { _id : billBoardDetail.Type, Description : utility.getKeyByValue(BILLBOARD_TYPES,billBoardDetail.Type)}
                    }
                    return x;
                })
            };
            let exception = res?.data?.Exceptions ?? [];
            if(exception.length > 0){
                let exceptionMedia = await API.getData(ENTITYNAME.MediaEpisode,{ query : ["_id","in",exception]});
                console.log(exceptionMedia);
                if(exceptionMedia.success){
                    setExclusions(exceptionMedia.data)
                }
            }
            setGridData(mediaData)
            setFilterData(mediaData)
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setLoadingStatus(lang.no_record_found_error_message);
    }

    const loadcombo = async () => {
        let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
        setMediaTypes(MEDIATYPE);
        let mediaCategoryType = await utility.getMediaCategroyTypeWithMediaCategory();
        setMediaCategoryTypes(mediaCategoryType?.length > 0 ? mediaCategoryType?.filter(x => !x?.MediaCategory?.isLong ?? false) : []);
    }

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else if (e.target.name == "IsFetchFromLibrary") {
            setDataItem({ ...dataItem, IsFetchFromLibrary: !dataItem.IsFetchFromLibrary });
        } else if (e.target.name == "MediaType"){
            if(e.target.value.SID == MEDIATYPEENUM.BILLBOARD){
                setCollectionSelectionQuery([['MediaCategory.SID', '=', MEDIACATEGORIES.Commercial],['MediaCategoryType.Description', '=', "Billboards"]]);
            } else {
                setCollectionSelectionQuery(['MediaCategory.isLong', '=', false])
            }
            setGridData([]);
            setFilterData([]);
            setExclusions([]);
            setDataItem({ ...dataItem, MediaType: e.target.value });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }

        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (Object.keys(dataItem.MediaType).length == 0) {
            toast.error(`${lang.please_select_media_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (dataItem.IsFetchFromLibrary && Object.keys(dataItem.MediaCategoryType).length == 0) {
            toast.error(lang.please_select_media_category_type_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (!dataItem.IsFetchFromLibrary && gridData.length == 0) {
            toast.error(`${lang.atleast_attach_one_item_variation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (!dataItem.IsFetchFromLibrary && dataItem.MediaType.SID == MEDIATYPEENUM.FIXED) {
            let check = gridData.find(x => x.Slot == undefined || x.Slot == "00:00:00:00" || x.Slot.includes("_"));
            if (check) {
                toast.error(lang.missing_slot_time_for_error_message + check.AssetId, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false
            }
        }

        if (!dataItem.IsFetchFromLibrary && dataItem.MediaType.SID == MEDIATYPEENUM.BILLBOARD) {
            console.log(gridData)
            let checkBillBoardType = gridData.find(x => x.Type == undefined || Object.keys(x.Type).length == 0);
            if (checkBillBoardType) {
                toast.error(`Billboard Type is missing for ${checkBillBoardType.AssetId}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false
            }

            let checkParentMediaEpisode = gridData.find(x => 
                x.Type._id == BILLBOARD_TYPES.Fixed && 
                (x.ParentMediaEpisode == undefined || Object.keys(x.ParentMediaEpisode).length == 0));
            if (checkParentMediaEpisode) {
                toast.error(`Parent Media Episode is missing for ${checkParentMediaEpisode.AssetId}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false
            }
        }
        return true
    }

    const onSave = async () => {
        if (isValid()) {
            const saveData = {
                ...dataItem,
                Channel: {
                    _id: dataItem.Channel._id,
                    SID: dataItem.Channel.SID,
                    FullChannelName: dataItem.Channel.FullChannelName
                },
                MediaType: dataItem.MediaType,
                MediaEpisodes: (!dataItem.IsFetchFromLibrary && gridData?.length > 0) ? gridData.map(x => x?._id) : [],
                SlotDetail: (dataItem.MediaType.SID == MEDIATYPEENUM.FIXED && gridData?.length > 0) ? gridData.map(x => {
                    return {
                        MediaEpisode_id: x?._id,
                        Slot: utility.convertStringWithFramesToMilliseconds(x?.Slot) ?? 0
                    }
                }) : [],
                BillBoardDetail: (dataItem.MediaType.SID == MEDIATYPEENUM.BILLBOARD && gridData?.length > 0) ? gridData.map(x => {
                    return {
                        MediaEpisode_id: x?._id,
                        Type : x?.Type?._id,
                        ParentMediaEpisode : { _id : x?.ParentMediaEpisode?._id, AssetId : x?.ParentMediaEpisode?.AssetId } ?? {}
                    }
                }) : [],
                Exceptions: (dataItem.MediaType.SID == MEDIATYPEENUM.BILLBOARD && gridData?.length > 0) ? exclusions.map(x => x?._id) : [],
                MediaCategoryType: (dataItem.IsFetchFromLibrary && Object.keys(dataItem?.MediaCategoryType)?.length > 0) ? {
                    _id: dataItem?.MediaCategoryType?._id,
                    SID: dataItem?.MediaCategoryType?.SID,
                    Description: dataItem?.MediaCategoryType?.Description,
                    MediaCategorySID: dataItem?.MediaCategoryType?.MediaCategorySID
                } : {},
                Archive: dataItem.Archive ?? false,
                IsFetchFromLibrary: dataItem?.IsFetchFromLibrary ?? false,
                checkDuplicate: true,
                query: [["Channel.SID", "=", dataItem.Channel.SID], ["Name", "=", dataItem.Name], ["Archive", "=", false]]
            }
            console.log(saveData)
            let res = await API.saveData(ENTITYNAME.Variation, saveData);
            console.log(res)
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.VARIATION, data: res.data, message: res.message };
                API.SaveLogs(logData);
                navigate("/home/Variation");
                utility.deleteLocalStorageItem(ENTITYNAME.Variation);
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const viewCollectionMediaEpisode = () => {
        setOpenCollectionMediaEpisode(!openCollectionMediaEpisode);
    }

    const gridSave = (dataList) => {
        let updateData = dataList.map(x => {
            return {
                ...x,
                Slot: "00:00:00:00"
            }
        }) ?? [];
        let newData = utility.checkDuplicateInData(updateData, gridData, "Title");
        setGridData([...gridData, ...newData]);
        setFilterData([...filterData, ...newData]);
    }

    const getExclusionMedia = (dataList) => {
        let newData = utility.checkDuplicateInData(dataList, exclusions, "Title");
        setExclusions([...exclusions, ...newData]);
    }

    const getParentMediaEpisodeData = (dataList) => {
        const newData = filterData.map((item) =>
            item._id === selectedItemRef.current
                ? {
                    ...item,
                    ParentMediaEpisode : dataList[0],
                }
                : item
        );
        setFilterData(newData);
        setGridData(newData)
    }

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onExclusionSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setExclusionSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    const onExclusionHeaderSelectionChange = useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setExclusionSelectedState(newSelectedState);
    }, []);

    const handleSelectedDelete = () => {
        let selectedData = filterData.filter((item) => !selectedState[idGetter(item)])
        setGridData(gridData.filter((item) => !selectedState[idGetter(item)]));
        setFilterData(selectedData);
        setSelectedState({});
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const handleSelectedExclusionDelete = () => {
        setExclusions(exclusions.filter((item) => !selectedExclusionState[idGetter(item)]));
        setExclusionSelectedState({});
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const onSearch = (searchText) => {

        setSearchText(searchText);

        let keyword = searchText?.toLowerCase() ?? "";

        if (keyword == undefined || keyword == "") {
            setFilterData(gridData);
            return;
        }

        let filterSearchData = gridData.filter((item) => {
            return JSON.stringify(item?.Title)?.toLowerCase()?.includes(keyword) || JSON.stringify(item?.AssetId)?.toLowerCase()?.includes(keyword);
        });

        setFilterData(filterSearchData);

    };

    const editSlot = (dataItem, selectedId) => {
        selectedItemRef.current = selectedId;
        setShowSlotEditForm(true);
        setSlotData(dataItem);
    }

    const editParentMediaEpisode = (dataItem, selectedId) => {
        selectedItemRef.current = selectedId;
        setOpenParentMediaCollection(true);
    }

    const MyEditCommandCell = (props) => {
        let icon = props.icon ?? 'edit';
        let showSlotTime = props.showSlotTime ?? true
        return (
            <td>
                {showSlotTime && <span className="ml-3">{props?.dataItem?.Slot}</span>}
                {!showSlotTime && props.dataItem.Type && props.dataItem.Type?._id != BILLBOARD_TYPES.Generic && <span className="ml-3">{props?.dataItem?.ParentMediaEpisode?.AssetId}</span>}
                {(showSlotTime || props.dataItem.Type && props.dataItem.Type?._id != BILLBOARD_TYPES.Generic) && <button
                    className="btn btn-sm btn-icon btn-icon-only" 
                    style={{ marginTop: '-4px', color: 'blue' }}
                    onClick={props.onClick}
                ><i className={`fa fa-${icon}`}></i>
                </button>}
            </td>
        );
    }

    const handleEditSlot = () => {
        let allUpdated = gridData.map(x => {
            if (selectedItemRef.current == x._id) {
                x.Slot = slotData;
            }
            return x;
        });
        let filteredUpdated = filterData.map(x => {
            if (selectedItemRef.current == x._id) {
                x.Slot = slotData;
            }
            return x;
        });

        setGridData(allUpdated);
        setFilterData(filteredUpdated);
        setShowSlotEditForm(false);
    }

    const MyDropDownCell = (props) => {
        let disable = props.editor;
        return <>{disable ?
            <DropDownCell
                {...props}
                data={Object.keys(BILLBOARD_TYPES).map((key) => { return { _id: BILLBOARD_TYPES[key], Description: key  } })}
            />
            :
            <input
                disabled={true} 
                value={utility.getKeyByValue(BILLBOARD_TYPES, BILLBOARD_TYPES.Fixed)} 
                style={{ fontSize: "12px", margin: "20px 0px 0px 10px" }}
            />

        }</>
    }

    const rowClick = (event) => {
        setEditID(event.dataItem._id);
    };

    const itemChange = (event) => {
        const inEditID = event.dataItem._id;
        const field = event.field || "";
        const newData = filterData.map((item) =>
            item._id === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        setFilterData(newData);
        setGridData(newData)
    };

    return (<>
        <div className="row m-1">
            <div className="col-12">
                <EditPageHeader title={(SID > 0) ? `${lang.edit_variation_editpage_header}` : `${lang.create_variation_editpage_header}`} onSubmit={onSave} onCancel={() => navigate("/home/Variation")} />
                <div className="row mt-2">
                    <div className="col-4">
                        <label htmlFor="TabView">{lang.name_label} *</label>
                        <input type="text" className="form-control form-control-sm" name="Name" value={dataItem.Name} onChange={onChange} />
                    </div>
                    <div className="col-4">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={channel}
                            name="Channel"
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="TabView">{lang.media_type_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={mediaTypes}
                            name="MediaType"
                            textField="Name"
                            dataItemKey="SID"
                            value={dataItem.MediaType}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className='col-4 mt-3'>
                        <input type={"checkbox"} name={"IsFetchFromLibrary"}
                            value={dataItem.IsFetchFromLibrary}
                            checked={dataItem.IsFetchFromLibrary}
                            onChange={onChange} />
                        <label className='ml-1'>{lang.isfetchfromlibrary_label}</label>
                    </div>
                    {dataItem.IsFetchFromLibrary &&
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.media_category_type_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={mediaCategoryTypes}
                                name="MediaCategoryType"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.MediaCategoryType}
                                onChange={onChange}
                            />
                        </div>
                    }
                    <div className='col mt-3'>
                        <input type={"checkbox"} name={"Archive"}
                            value={dataItem.Archive}
                            checked={dataItem.Archive}
                            onChange={onChange} />
                        <label className='ml-1'>{lang.archive}</label>
                    </div>
                </div>

                {!dataItem.IsFetchFromLibrary &&
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <AddRoundButton style={{ marginTop: '5px', backgroundColor: dataItem.IsFetchFromLibrary ? 'lightgray' : '' }} title={lang.add_media_episode_button_tooltip} onClick={viewCollectionMediaEpisode} />
                                    {(filterData.filter((item) => selectedState[idGetter(item)]).length != 0) && <DeleteRoundButton style={{ marginTop: '5px', marginLeft: '5px' }} title={lang.delete_all_button_tooltip} onClick={() => ConfirmDeleteAlert(() => handleSelectedDelete(), () => { })} />}
                                    <div className="pull-right">
                                        <div className="input-group input-group mb-2" >
                                            <input type="text" className="form-control shadow-inset-2 pt-0 pb-0 ml-2" id="searchInput" aria-label="type 2 or more letters" value={searchText} placeholder={lang.search_button_text} onChange={(e) => onSearch(e.target.value)} />
                                            <span className="input-group-text">
                                                <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Grid
                                data={filterData?.map((item) => ({
                                    ...item,
                                    inEdit: item?._id === editID,
                                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                                }))}
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "multiple",
                                }}
                                className="vk-grid"
                                onSelectionChange={onSelectionChange}
                                onHeaderSelectionChange={onHeaderSelectionChange}
                                style={{ 
                                    height: dataItem.MediaType.SID == MEDIATYPEENUM.BILLBOARD  ? "27.5vh" : "55vh", 
                                    marginTop: "04px", 
                                    overflow: "hidden" 
                                }}
                                editField="inEdit"
                                    onRowClick={rowClick}
                                    onItemChange={itemChange}
                                >
                                <GridNoRecords>{loadingStatus}</GridNoRecords>
                                <GridColumn
                                    field={SELECTED_FIELD}
                                    width="50px"
                                    headerSelectionValue={
                                        filterData?.findIndex((item) => !selectedState[idGetter(item)]) === -1
                                    }
                                />
                                <Column field="Title" title={lang.title_column} editable={false} />
                                <Column field="MediaCategory.Description" title={lang.media_category_column} editable={false} />
                                <Column field="MediaCategoryType.Description" title={lang.media_category_type_column} editable={false} />
                                <Column field="AssetId" title={lang.asset_id_column} editable={false} />
                                <Column field="Archive" title={lang.archived_column} editable={false} cell={ArchiveIconCell} />
                                {dataItem?.MediaType?.SID == MEDIATYPEENUM.FIXED && 
                                    <Column 
                                        field="Slot" 
                                        title={lang.slot_column} 
                                        editable={false} 
                                        cell={(props) => <MyEditCommandCell {...props} icon={'edit'} onClick = {() => editSlot(props?.dataItem?.Slot, props?.dataItem?._id)}/>} 
                                    />
                                }
                                {dataItem?.MediaType?.SID == MEDIATYPEENUM.BILLBOARD &&
                                    <Column 
                                        field="Type" 
                                        title={lang.type_column} 
                                        cell={MyDropDownCell} 
                                        editable={true} 
                                        editor={true} 
                                    />
                                }
                                {dataItem?.MediaType?.SID == MEDIATYPEENUM.BILLBOARD &&
                                    <Column 
                                        field="ParentMediaEpisode" 
                                        title={"Parent Media Episode"} 
                                        editable={false} 
                                        cell={(props) => <MyEditCommandCell {...props} icon={'ellipsis'} showSlotTime={false} onClick = {() => editParentMediaEpisode(props?.dataItem?.Slot, props?.dataItem?._id) }/>} 
                                    />
                                }
                            </Grid>
                        </div>
                </div>}

                {dataItem.MediaType && dataItem.MediaType.SID == MEDIATYPEENUM.BILLBOARD &&
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <AddRoundButton style={{ marginTop: '5px', backgroundColor: dataItem.IsFetchFromLibrary ? 'lightgray' : '' }} title={lang.add_media_episode_button_tooltip} onClick={() => setOpenLongFormMediaCollection(true)} />
                                    {(exclusions.filter((item) => selectedExclusionState[idGetter(item)]).length != 0) && <DeleteRoundButton style={{ marginTop: '5px', marginLeft: '5px' }} title={lang.delete_all_button_tooltip} onClick={() => ConfirmDeleteAlert(() => handleSelectedExclusionDelete(), () => { })} />}
                                    <div className="pull-right">
                                        <div className="input-group input-group mb-2" >
                                            <input type="text" className="form-control shadow-inset-2 pt-0 pb-0 ml-2" id="searchInput" aria-label="type 2 or more letters" value={searchText} placeholder={lang.search_button_text} onChange={(e) => onSearch(e.target.value)} />
                                            <span className="input-group-text">
                                                <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Grid
                                data={exclusions?.map((item) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedExclusionState[idGetter(item)],
                                }))}
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "multiple",
                                }}
                                className="vk-grid"
                                onSelectionChange={onExclusionSelectionChange}
                                onHeaderSelectionChange={onExclusionHeaderSelectionChange}
                                style={{ height: "27.5vh", marginTop: "04px", overflow: "hidden" }}>
                                <GridNoRecords>{loadingStatus}</GridNoRecords>
                                <GridColumn
                                    field={SELECTED_FIELD}
                                    width="50px"
                                    headerSelectionValue={
                                        exclusions?.findIndex((item) => !selectedExclusionState[idGetter(item)]) === -1
                                    }
                                />
                                <Column field="Title" title={lang.title_column} editable={false} />
                                <Column field="AssetId" title={lang.asset_id_column} editable={false} />
                                <Column field="MediaCategory.Description" title={lang.media_category_column} editable={false} />
                            </Grid>
                        </div>
                    </div>
                }

                <div className="row mt-2" style={dataItem.IsFetchFromLibrary ? { position: "fixed", bottom: 0 } : {}}>
                    <div className="col-12 ">
                        <p>If fetch from library is selected, media will be used from schedule valid for that day</p>
                        {!dataItem.IsFetchFromLibrary && <div className="pull-right" style={{ marginTop: '-40px' }}>
                            <label >{lang.selected_label} : {filterData.filter((item) => selectedState[idGetter(item)]).length}</label>
                            <label className="ml-2 ">{lang.total_label} : {filterData.length}</label>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        {openCollectionMediaEpisode && <CollectionSelection addButtonTitle={lang.select_collection_button_text} title={lang.select_media_episode_collection_header} entityname={ENTITYNAME.MediaEpisode} setDataList={gridSave} wherestatement={collectionSelectionQuery} closeForm={viewCollectionMediaEpisode} width={"50vw"} />}
        {openLongFormMediaCollection && <ContractCollectionSelection addButtonTitle="Select Media to Exclude" title={lang.media_library_dialog_header} hideSeriesSeason={true} wherestatement={['MediaCategory.isLong', '=', true]} setDataList={getExclusionMedia} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setOpenLongFormMediaCollection(false)} width={"50vw"} height={"66vh"} />}
        {openParentMediaCollection && <ContractCollectionSelection addButtonTitle="Select Parent Media Episode" title={lang.media_library_dialog_header} mode={'single'} hideSeriesSeason={true} wherestatement={['MediaCategory.isLong', '=', true]} setDataList={getParentMediaEpisodeData} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setOpenParentMediaCollection(false)} width={"50vw"} height={"66vh"} />}

        {showSlotEditForm &&
            <BossDialog
                title={lang.edit_slot_dialog_header}
                onClose={() => setShowSlotEditForm(false)}
                width={"300px"}
            >
                <EditPageHeader onSubmit={handleEditSlot} showTitle={false} onCancel={() => setShowSlotEditForm(false)}></EditPageHeader>
                <div className="row mt-2">
                    <div className="col-12">
                        <label htmlFor="Slot">{lang.slot_label} *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="Slot"
                            value={slotData}
                            onChange={(e) => setSlotData(e.target.value)}
                        />
                    </div>
                </div>
            </BossDialog>
        }
    </>)
}

export default VariationEditForm;