import React from 'react';
import { useTranslation } from '../../../locale/useTranslation';
import DeleteRoundButton from '../../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import RoundButton from '../../../framework/forms/helpercomponents/buttons/RoundButton';

const StorageCard = (props) => {

    const { title, totalStorage = 0, usedStorage = 0, showFreeMemory = false, freeStorage = 0, showDeleteStorage = false, handleDeleteStorage } = props;
    const totalStorageGB = parseFloat(totalStorage);
    const usedStorageGB = parseFloat(usedStorage);

    // Calculate percentages
    let usedPercentage = ((usedStorageGB / totalStorageGB) * 100).toFixed(1);
    usedPercentage = isNaN(usedPercentage) ? 0 : usedPercentage;

    const lang = useTranslation();

    return (
        <div className="card p-3 pt-0 pb-0 mb-2" style={{ height: "110px", borderRadius: '8px', letterSpacing: '1px', textTransform: 'uppercase' }}>
            <div style={{ color: "#919496", fontSize: '11px' }}>
                {title}
                {showDeleteStorage &&
                    <span className="float-right">
                        <RoundButton icon="trash" btnColor="danger" title={lang.delete_storage_button_tooltip} style={{ marginTop: '-10px', width: '23px', fontSize: '12px', paddingTop: '3px', height: '23px', color: 'white' }} onClick={handleDeleteStorage} />
                    </span>
                }
            </div>
            <div className="position-relative rounded" style={{ height: "20px", margin: 0, backgroundColor: '#e4e4e4' }}>
                <div
                    className={usedPercentage > 80 ? "bg-danger rounded" : "bg-primary rounded"}
                    style={{
                        width: `${usedPercentage}%`,
                        height: "100%",
                    }}
                ></div>
                <div
                    className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                    style={{
                        top: 0,
                        left: 0,
                        fontSize: '10px',
                        color: 'white',
                        pointerEvents: 'none',
                    }}
                >
                    {`${usedPercentage}% used`}
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <div style={{ fontSize: '11px' }}>{lang.total_label}</div>
                    <div style={{ fontSize: '15px', fontWeight: 800 }}>{totalStorage}</div>
                </div>
                <div>
                    <div style={{ fontSize: '11px' }}>{lang.used_label}</div>
                    <div style={{
                        fontSize: '15px', fontWeight: 800, color:
                            usedPercentage > 90 ? 'red' : '#919496'
                    }}>{usedStorage}</div>
                </div>
                {showFreeMemory && <div>
                    <div style={{ fontSize: '11px' }}>{lang.free_label}</div>
                    <div style={{ fontSize: '15px', fontWeight: 800, color: 'green' }}>{freeStorage}</div>
                </div>}
            </div>
        </div>
    )
};

export default StorageCard;
