/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn, GridNoRecords, getSelectedState } from '@progress/kendo-react-grid';
import moment from 'moment';
import { ENTITYNAME, LOGEVENT, MODULE } from '../../../framework/constant/constant';
import * as API from '../../../framework/API/api';
import CollectionSelection from '../../../framework/forms/CollectionSelection'
import ActionButton from '../../../framework/forms/helpercomponents/buttons/ActionButton'
import { CampaignColumns } from '../../../framework/utility/customColumns';
import { TimeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import UpdateLineNumberMenuCommandCell from './UpdateLineNumberMenuCommandCell';
import UpdateBookingLineNumberEditForm from './UpdateBookingLineNumberEditForm';
import { toast } from 'react-toastify';
import { WrappedTextCell } from '../../../framework/forms/helpercomponents/CustomGridCells/WrappedTextCell';
import { useTranslation } from '../../../locale/useTranslation';
import { ConfirmDeleteAlert } from '../../../ConfirmAlert';
import { getter } from "@progress/kendo-react-common";
import UpdateBookingLineNumberEditMultipleForm from './UpdateBookingLineNumberEditMultipleForm';
import { bookingSocket } from '../../../framework/socket/bookingSocket';
import { utility } from '../../../framework/utility/utilityProvider';
import { useRef } from 'react';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { SalesHelper } from '../SalesHelper';
import RoundButton from '../../../framework/forms/helpercomponents/buttons/RoundButton';

const DATA_ITEM_KEY = "LineNumber";
const editField = "inEdit";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const MyNumericCell = (props) => {

    var field = props.field || "";
    var saveField = field.split('.');

    var daySpots = 0;
    var isScheduled = false;
    if (props.dataItem[saveField[0]] != undefined) {
        daySpots = props.dataItem[saveField[0]]['daySpots'];
        isScheduled = props.dataItem[saveField[0]].isScheduled;
    }

    const style = {
        backgroundColor: isScheduled ? "#F55050" : "#C9F4AA",
        border: "1px solid lightgrey",
        textAlign: 'center'
    };

    return <td style={style}> {
        daySpots
    } </td>
};

const DateDayCell = (props) => {
    const title = props.title || "";
    const displayValue = title.split(' ');
    var date = moment(new Date(displayValue[0])).format('YYYY-MMM-DD');

    return (
        <div colSpan={props.colSpan} style={{ padding: "0px", fontSize: '13px', textAlign: "center" }}>
            {date}<br />
            {`(${displayValue[1]})`}
        </div>
    );
};


const UpdateBookingLineNumber = (props) => {

    const lang = useTranslation();
    const [dataItem, setDataItem] = useState({});
    const [editDataItem, setEditDataItem] = useState({});
    const [openCampaignSelection, setOpenCampaignSelection] = useState(false);
    const [bookingCollection, setBookingCollection] = useState([]);
    const [showProgressBar, setShowProgressBar] = useState({
        okButton: false,
        progressBarPopup: false,
      });
      const [progressData, setProgressData] = React.useState({
        message: '',
        progress: 0,
      });

    const [openEditBookingPopup, setOpenEditBookingPopup] = useState(false);
    const [openMultiEditBookingPopup, setOpenMultiEditBookingPopup] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState({ CampaignNo: '' });
    const [addDisable, setAddDisable] = useState(true);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [days, setDaysList] = useState([]);
    const daysRef = useRef([]);

    const setDays = (data) => {
        daysRef.current = data;
        setDaysList(data);
    }

    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    //footer variables
    const [bookedSpots, setBookedSpots] = useState(0);
    const [bookedSeconds, setBookedSeconds] = useState(0);
    const [bookedAmount, setBookedAmount] = useState(0);
    const [editDisable, setEditDisable] = useState(false);
    const [status, setStatus] = useState(`${lang.please_select_campaign_first_booking_error_message}`);

    const [selectedState, setSelectedState] = useState({});

    const bookingsForMultiEdit = React.useRef([]);

    useEffect(() => {

    }, []);

    //set Data from booking table according to the UI
    const setBookingGridData = (data) => {

        var distinctBookingLineNumberData = [];

        data.map((booking) => {
            var uniqDaysOfLine = [];
            distinctBookingLineNumberData.push(booking);

            daysRef.current.map((campaignDate) => {

                const date = new Date(campaignDate.split(' ')[0]).getTime();
                const daySpots = booking.Spots.filter(x => x.BookingDate == date);
                var tempDay = campaignDate.split(' ')[1];
                if(daySpots.length > 0 && !uniqDaysOfLine.includes(tempDay)){
                    uniqDaysOfLine.push(tempDay)
                }
                booking[campaignDate] = { daySpots: daySpots.length, isScheduled: daySpots.some(x => x.NominalOnAir > 0) };
                
            })
            booking.DaysOfWeek = uniqDaysOfLine.map((x) => x.substring(0,1)).join('/');
        })

        setBookingCollection(distinctBookingLineNumberData);
        if (distinctBookingLineNumberData.length > 0) {
            setStatus(`${lang.data_loaded_update_booking_line_number_success_message}`)
        }

    }

    const CreateDatesFromCampaign = async (bookingLines) => {

        console.log(bookingLines);
        if (bookingLines.length == 0) {
            setStatus(`${lang.no_data_found_booking_error_message}`);
        }
        else {
            setStatus(`${lang.loading_booking_update_booking_line_number_success_message}`);
        }

        var days = [];

        var monthNo = month.indexOf(dataItem.Month) + 1;
        var daysInMonth = new Date(dataItem.Year, monthNo, 0).getDate();
        for (var i = 0; i < daysInMonth; i++) {
            var date = new Date(dataItem.Year, monthNo - 1, i + 1);
            var day = daysOfWeek[date.getDay()];
            var dateString = moment(date).format("YYYY-MM-DD");

            days.push(`${dateString} ${day}`);
        }

        setDays(days);

        //set Data from booking table according to the UI
        setBookingGridData(bookingLines)
    }

    function validateSpotsBudgetSeconds(updatedDataItem) {

        //new totals
        var totalAmount = bookedAmount - editDataItem.TotalAmount + updatedDataItem.TotalAmount;
        var totalSpots = bookedSpots - editDataItem.TotalSpots + updatedDataItem.TotalSpots;
        var totalSeconds = bookedSeconds - editDataItem.TotalSeconds + updatedDataItem.TotalSeconds;

        if (totalSpots > parseInt(selectedCampaign.TargetSpots)) {
            toast.warn(`${lang.exceding_target_spots_limit_update_booking_line_number_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (totalAmount > parseInt(selectedCampaign.TargetBudget)) {

            //console.log("in update validation check");
            toast.warn(`${lang.exceding_target_budget_update_booking_line_number_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (totalSeconds > parseInt(selectedCampaign.TargetSeconds)) {
            toast.warn(`${lang.exceding_target_seconds_update_booking_line_number_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        // CHECKING IF COMMERCIALS ARE BLANK
        var emptyCommercials = bookingCollection.filter((val) => {
            return val.MediaEpisode == undefined;
        });

        if (emptyCommercials.length > 0) {
            var lineNo = "";
            emptyCommercials.forEach(element => {
                lineNo = lineNo + " , " + element.LineNumber

            });
            toast.warn(`${lang.commercials_not_updated_in_line_no_booking_error_message} - ` + lineNo, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        var invalidBookingSpots = getBookingsWithNegativeSpotsValue();
        if (invalidBookingSpots.length > 0) {
            var lineNo = "";
            invalidBookingSpots.forEach(booking => {
                lineNo = lineNo + booking.LineNumber + " , ";

            });
            toast.error(`${lang.invalid_spots_value_line_no_booking_error_message} : ` + lineNo, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;

        }

        return true;

    }

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    const getBookingsWithNegativeSpotsValue = () => {

        var bookingsWithInvalidSpot = [];

        bookingCollection.forEach(booking => {
            // console.log(booking);

            // to prevent adding current booking object multitimes as break statement was not working in 
            var currenBookingIsAdded = false;
            days.forEach(day => {



                if (booking[day] != undefined && !currenBookingIsAdded && booking[day]["daySpots"] < 0) {
                    //console.log(booking.LineNumber + " has invalid spots on " + day );
                    bookingsWithInvalidSpot.push(booking);
                    currenBookingIsAdded = true;
                }

            });
        });

        return bookingsWithInvalidSpot;
    }

    const onChange = async (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });

        if (e.target.name == "Month" || e.target.name == "Year") {

            if (dataItem.Year == undefined) {
                //need to check
                console.log("in");
            }

            var days = [];
            var year = e.target.name == "Year" ? e.target.value : dataItem.Year;
            var monthNo = e.target.name == "Month" ? month.indexOf(e.target.value) + 1 : month.indexOf(dataItem.Month) + 1;
            var daysInMonth = new Date(year, monthNo, 0).getDate(); //daysInMonth to show  

            var resBooking = await loadBookingData(selectedCampaign, year, monthNo, daysInMonth);
            if (resBooking.length == 0) {
                setTimeout(() => {
                    setStatus(`${lang.no_data_found_booking_error_message}`)
                }, 3000)
            } else {
                setStatus(`${lang.loading_data_update_booking_line_number_error_message}`)
            }

            for (var i = 0; i < daysInMonth; i++) {
                var firstDate = new Date(year, monthNo - 1, i + 1);
                var day = daysOfWeek[firstDate.getDay()];
                var date = moment(firstDate).format("YYYY-MM-DD");
                days.push(`${date} ${day}`);
            }

            setDays(days);

            //set Data from booking table according to the UI
            setBookingGridData(resBooking)

        }
    };

    const loadBookingData = async (campaign, year, monthNo, daysInMonth) => {

        // update footer total booked status
        updateFooterValues(campaign);

        var month = monthNo - 1;
        var tempStartDate = new Date(Date.UTC(year, month, 1, 0, 0, 0)).getTime();
        var tempEndDate = new Date(Date.UTC(year, month, daysInMonth, 0, 0, 0)).getTime();
        const filterQ = [['Campaign_Id', '=', campaign._id], ['BookingDate', '>=', tempStartDate], ['BookingDate', '<=', tempEndDate], ['Purge', '=', false]];

        var resBooking = await API.getBookedDataByLineNumber({ query: filterQ });

        console.log(resBooking);

        return resBooking.success ? resBooking.data : null;
    }

    const updateFooterValues = async (campaign) => {


        var existingBookings = await API.getCampaignsExistingBookingDetails(campaign._id);

        if (existingBookings.success) {

            setBookedAmount(existingBookings.data.BookedAmount);
            setBookedSpots(existingBookings.data.BookedSpots);
            setBookedSeconds(existingBookings.data.BookedSeconds);
        }

    }

    const onSelectCampaign = () => {
        setOpenCampaignSelection(true);
        setSelectedState({});
    }

    const onEditMultiple = () => {

        if (bookingsForMultiEdit.current.length > 0) {
            setOpenMultiEditBookingPopup(true);
        } else {
            toast.info(`${lang.please_select_atleast_one_value_to_update_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });

        }


    }

    const onSetSelectedCampaign = async (dataList) => {

        setStatus(`${lang.loading_booking_update_booking_line_number_success_message}`)

        const campaign = dataList[0];
        setSelectedCampaign(campaign);

        const monthsYears = await getValidMonthsAndYears(campaign);
        setMonths(monthsYears.months);
        setYears(monthsYears.years);

        if (monthsYears.years.length > 0) dataItem.Year = monthsYears.years[0];
        if (monthsYears.months.length > 0) dataItem.Month = monthsYears.months[0];

        var daysInMonth = new Date(dataItem.Year, month.indexOf(dataItem.Month) + 1, 0).getDate();

        var resBooking = await loadBookingData(campaign, dataItem.Year, month.indexOf(dataItem.Month) + 1, daysInMonth);
        console.log(resBooking);

        if (resBooking) {
            CreateDatesFromCampaign(resBooking)
        }
        else {
            toast.warn("There are some error in load booking", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    async function getValidMonthsAndYears(campaign) {

        var yearTemp = [];
        var monthTemp = [];

        var tempDate = new Date(campaign.PeriodFrom);
        var periodTo = new Date(campaign.PeriodTo);

        while (tempDate <= periodTo) {

            //for year insert
            if (!yearTemp.includes(tempDate.getFullYear())) {
                yearTemp.push(tempDate.getFullYear());
            }

            //for month insert
            if (!monthTemp.includes(month[tempDate.getMonth()])) {
                monthTemp.push(month[tempDate.getMonth()]);
            }

            tempDate = tempDate.addDays(28);

            if (tempDate > periodTo) {
                if (!yearTemp.includes(periodTo.getFullYear())) {
                    yearTemp.push(periodTo.getFullYear());
                }
                if (!monthTemp.includes(month[periodTo.getMonth()])) {
                    monthTemp.push(month[periodTo.getMonth()]);
                }
            }



            // setting firstyear and month flrmo list as default
            if (yearTemp.length > 0) dataItem.Year = yearTemp[0];
            if (monthTemp.length > 0) dataItem.Month = monthTemp[0];
        }

        return { years: yearTemp, months: monthTemp };

    }

    // Functions to modify grid data
    const onInLineRemoveBooking = async (dataItem) => {

        let deleteRes = await API.deleteBookingLine(dataItem);
        if (deleteRes.success) {
            let filteredGridData = bookingCollection.filter((obj) => obj._id != dataItem._id)
            setBookingCollection(filteredGridData);
            updateFooterValues(selectedCampaign);
            bookingSocket.onDelete(dataItem, days);

            console.log(dataItem);
            let logCapture = { event: LOGEVENT.DELETE_BOOKING_LINE_NUMBER, module: MODULE.BOOKING, data: dataItem, message: "Delete Booking Line Number", _id : dataItem.Campaign_Id + '_' + dataItem.LineNumber };
            API.SaveLogs(logCapture);
        } else {
            toast.warn(deleteRes.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const itemChange = (event) => {
        var field = event.field || "";
        var saveField = field.split('.');

        const newData = bookingCollection.map((item) =>
            item.LineNumber === event.dataItem.LineNumber
                ? {
                    ...item,
                    [saveField[0]]: { daySpots: event.value, isScheduled: event.dataItem[saveField[0]].isScheduled },
                }
                : item
        );
        setBookingCollection(newData);
    };

    const handleOpenEditBookingPopup = (dataItem) => {

        if (dataItem.BookingStatusSID != undefined && dataItem.BookingStatusSID > 2) {
            toast.info(`${lang.bill_is_already_generated_for_this_linenumber_update_booking_line_number_success_message}`, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        } else {
            setOpenEditBookingPopup(true);
            setEditDataItem(dataItem);

            //check if any line is scheduled.
            var isScheduledData = Object.keys(dataItem).filter((item) => dataItem[item] != undefined && dataItem[item].isScheduled == true);
            if (isScheduledData.length > 0) {
                setEditDisable(true);
            } else {
                setEditDisable(false);
            }
        }
    }

    const onUpdateBookingLine = async (updatedDataItem) => {

        if (validateSpotsBudgetSeconds(updatedDataItem)) {

            var response = await API.updateBookingLineNumber(updatedDataItem, days);

            if (response.success) {

                console.log(updatedDataItem);

                let logCapture = { event: LOGEVENT.UPDATE_BOOKING_LINE_NUMBER, module: MODULE.BOOKING, data: SalesHelper.getBookingLogSchema(updatedDataItem), message: "Update Booking Line Number", _id : updatedDataItem.Campaign_Id + '_' + updatedDataItem.LineNumber};
                API.SaveLogs(logCapture);
        
                //finding index of updated Line 
                let index = bookingCollection.findIndex((record) => record._id === updatedDataItem._id);
                bookingCollection[index] = updatedDataItem;
                setBookingCollection(bookingCollection);
                setOpenEditBookingPopup(false);

                toast.success(`${lang.booking_update_successfully}`, {
                    position: toast.POSITION.TOP_RIGHT
                });

                //reloading data after save
                var daysInMonth = new Date(dataItem.Year, month.indexOf(dataItem.Month) + 1, 0).getDate();
                var resUpdatedBooking = await loadBookingData(selectedCampaign, dataItem.Year, month.indexOf(dataItem.Month) + 1, daysInMonth);
                // console.log(resUpdatedBooking);
                resUpdatedBooking.sort((a, b) => (a.LineNumber > b.LineNumber) ? 1 : -1)
                setBookingGridData(resUpdatedBooking);
                updateFooterValues(selectedCampaign);

                bookingSocket.onUpdateBooking(updatedDataItem, days);

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });

                return;

            }


        }
    }

    const onSelection = (selectedState) => {
        var selectedDataList = [];
        //loop for setting the selected obj in array
        bookingCollection.map((obj) => {
            var key = obj.LineNumber;
            //if true means checked
            if (selectedState[key]) {
                selectedDataList.push(obj);
            }
        });

        //setSelectedBookings(selectedDataList);
        // checking if any of the selected booking has scheduled spots
        for (let i = 0; i < selectedDataList.length; i++) {
            var booking = selectedDataList[i];
            // console.log(booking);
            var isScheduledData = Object.keys(booking).filter((item) => booking[item] != undefined && booking[item].isScheduled == true);
            if (isScheduledData.length > 0) {
                setEditDisable(true);
                break; //   breaking loop if any item is found scheduled
            } else {
                setEditDisable(false);
            }
        }

        bookingsForMultiEdit.current = selectedDataList;
    }


    const onSelectionChange = useCallback((event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);
            onSelection(newSelectedState);

        }
    );

    const onHeaderSelectionChange = useCallback((event) => {

        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;

        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            // console.log(item);
            newSelectedState[idGetter(item)] = checked;

        });
        setSelectedState(newSelectedState);

        onSelection(newSelectedState);

    });


    const updateBookingsOnMultiEditSave = async (updatedBookings) => {

        console.log(updatedBookings);

        for (let i = 0; i < updatedBookings.length; i++) {
            var booking = updatedBookings[i];
            var response = await API.updateBookingLineNumber(booking, days);

            if (response.success) {
                bookingSocket.onUpdateBooking(booking, days);
                toast.success(`${lang.booking_update_successfully}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

        }

        //reloading data after save
        var daysInMonth = new Date(dataItem.Year, month.indexOf(dataItem.Month) + 1, 0).getDate();
        var resUpdatedBooking = await loadBookingData(selectedCampaign, dataItem.Year, month.indexOf(dataItem.Month) + 1, daysInMonth);
        console.log(resUpdatedBooking);
        resUpdatedBooking.sort((a, b) => (a.LineNumber > b.LineNumber) ? 1 : -1)
        setBookingGridData(resUpdatedBooking);
        updateFooterValues(selectedCampaign);
        setOpenMultiEditBookingPopup(false);
    }

    const MyCommandCell = (props) => (
        <UpdateLineNumberMenuCommandCell {...props} onRemove={(e) => ConfirmDeleteAlert(() => onInLineRemoveBooking(e), () => { })} onEdit={handleOpenEditBookingPopup} />
    );

    const onDeleteMultiple = async() => {
        let tempBookingData = [...bookingCollection];
        if (bookingsForMultiEdit.current.length > 0) {
            setShowProgressBar({ okButton: false, progressBarPopup: true });

            for (let x = 0; x < bookingsForMultiEdit.current.length; x++) {
                const item = bookingsForMultiEdit.current[x];
                tempBookingData = tempBookingData.filter((y) => y.LineNumber != item.LineNumber);
                setProgressData({ message: 'Deleting', progress: Math.ceil((100 * (x + 1)) / bookingsForMultiEdit.current.length) });
                await onInLineRemoveBooking(item);
            }

            setProgressData({ message: 'Process completed', progress: 100 });
            setBookingCollection(tempBookingData);
            setShowProgressBar({ progressBarPopup: true, okButton: true })
        } else {
            toast.error(`Atleast select one line to delete`, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    const MyTimeCell = (props) => <TimeCell {...props} style={{ textAlign: 'center' }} />;
    const campaignDetailHeaderStyle = { fontSize: "12px", fontWeight: "bold" };
    const campaignDetailValueStyle = { color: "blue" };

    return (<>

        <h1 className="page-title txt-color-bludeDark">
            <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }}></i>
            <span>{lang.update_booking_line_number_label}</span>
        </h1>

        <div className='row'>
            <div className='col-7' style={{ paddingRight: '20px' }}>
                <div style={{ display: 'flex' }}>
                    <div className="form-group" style={{width: '150px'}}>
                        <label htmlFor="TabView">{lang.campaign_label}</label>
                        <input name="Title" type="text" className="form-control form-control-sm" value={selectedCampaign.CampaignNo} onChange={() => ""} disabled={true} />
                    </div>
                    <ActionButton style={{ marginTop: "25px", height: "30px", borderRadius: "5px" }} title={lang.select_campaign_collection_tooltip} name={"..."} onClick={onSelectCampaign} />
                    <div style={{ margin: '0px 5px 0px 5px' }} className="form-group">
                        <label htmlFor="TabView">{lang.year_label}</label><br />
                        <DropDownList
                            data={years}
                            name="Year"
                            value={dataItem.Year}
                            onChange={onChange}
                            style={{ width: "120px" }}
                            validator={(value) => value ? "" : "Please select value"}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.months_label}</label><br />
                        <DropDownList
                            data={months}
                            name="Month"
                            style={{ width: "120px" }}
                            value={dataItem.Month}
                            onChange={onChange}
                            validator={(value) => value ? "" : "Please select value"}
                        />
                    </div>
                    {(bookingCollection.filter((item) => selectedState[idGetter(item)]).length != 0) && <> <RoundButton btnColor="primary" icon="pencil" style={{ marginTop: "24px", height: "29px", marginLeft: '5px', color: 'white' }} title={lang.edit_multiple_tooltip} name={lang.edit_multiple_button_text} onClick={onEditMultiple} />
                    <RoundButton icon="trash" btnColor="danger" style={{ marginTop: "24px", height: "29px", marginLeft: '5px', color: 'white' }} title={lang.delete_multiple_button_tooltip} name={lang.delete_multiple_button_text} onClick={() => ConfirmDeleteAlert(() => onDeleteMultiple(), () => { })} /> </>}
                </div>
                {openCampaignSelection && <CollectionSelection addButtonTitle={lang.select_button_text} title={lang.booking_collection_header} customColumns={CampaignColumns} entityname={ENTITYNAME.Campaign} closeForm={() => setOpenCampaignSelection(!openCampaignSelection)} wherestatement={[['Archive', '=', false],['IsDraft', '!=', true]]} setDataList={(dataList) => onSetSelectedCampaign(dataList)} mode={'single'} width={"50vw"} />}

            </div>
            <div className='col-5 '>
                {selectedCampaign.CampaignNo != "" &&
                    <div style={{ display: "flex" }} className='float-right'>
                        <div className="form-group marlr">
                            <label htmlFor="TabView" style={campaignDetailHeaderStyle}>{lang.from_label}</label><br />
                            <p style={campaignDetailValueStyle}>{moment(selectedCampaign.PeriodFrom).format("DD-MM-YYYY")}</p>
                        </div>

                        <div className="form-group marlr">
                            <label htmlFor="TabView" style={campaignDetailHeaderStyle}>{lang.to_label}</label><br />
                            <p style={campaignDetailValueStyle}>{moment(selectedCampaign.PeriodTo).format("DD-MM-YYYY")}</p>
                        </div>

                        <div className="form-group marlr">
                            <label htmlFor="TabView" style={campaignDetailHeaderStyle}>{lang.total_spot_label}</label><br />
                            <p style={campaignDetailValueStyle}>{selectedCampaign.TargetSpots}</p>
                        </div>

                        <div className="form-group marlr">
                            <label htmlFor="TabView" style={campaignDetailHeaderStyle}>{lang.total_seconds_label}</label><br />
                            <p style={campaignDetailValueStyle}>{selectedCampaign.TargetSeconds}</p>
                        </div>

                        <div className="form-group marlr">
                            <label htmlFor="TabView" style={campaignDetailHeaderStyle}>{lang.total_budget_label}</label><br />
                            <p style={campaignDetailValueStyle}>{selectedCampaign.TargetBudget}</p>
                        </div>

                    </div>
                }
            </div>
        </div>

        {/* grid */}
        <div className='row'>
            <div className='col-lg-12 col-md-12'>
                {/* <Grid data={bookingCollection} dataItemKey={DATA_ITEM_KEY} style={{ height: "62vh" }} onItemChange={itemChange} editField={editField}> */}

                <Grid data={bookingCollection.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                    }}
                    resizable = {true}
                    onSelectionChange={onSelectionChange}
                    onItemChange={itemChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    style={{ height: "62vh" }}>

                    <GridColumn
                        field={SELECTED_FIELD}
                        width="50px"
                        headerSelectionValue={
                            bookingCollection.findIndex((item) => !selectedState[idGetter(item)]) === -1
                        }
                        locked={true}
                    />
                    <GridNoRecords>{status}</GridNoRecords>

                    <GridColumn cell={MyCommandCell} width={"60px"} locked={true} />
                    <GridColumn field="LineNumber" title={lang.line_no_column} editable={false} width={"80px"} cell={WrappedTextCell} />
                    <GridColumn field="Channel.FullChannelName" title={lang.channel_column} editable={false} width={"80px"} cell={WrappedTextCell} />
                    <GridColumn field="MediaEpisode.Title" title={lang.commercial_column} editable={false} width={"100px"} />
                    <GridColumn field="Program.Title" title={lang.program_column} editable={false} width={"80px"} cell={WrappedTextCell} />
                    <GridColumn field="MediaEpisode.AssetId" title={lang.assetId_column} editable={false} width={"100px"} cell={WrappedTextCell} />

                    {/* to ask for duration */}
                    <GridColumn field="MediaEpisode.Duration" title={lang.duration_column} editable={false} width={"100px"} cell={MyTimeCell} />
                    <GridColumn field="CampaignType.Description" title={lang.type_column} editable={false} width={"80px"} cell={WrappedTextCell} />
                    <GridColumn field="DealType.Description" title={lang.deal_type_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="SpotRate" title={lang.spot_rate_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="Rate10Sec" title={lang.rate10sec_label} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="Position.Description" title={lang.position_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="DaysOfWeek" title={lang.days_of_week_column} editable={false} width={"100px"} />
                    <GridColumn field="TimeRangeFrom" title={lang.from_time_column} editable={false} width={"80px"} cell={MyTimeCell} />
                    <GridColumn field="TimeRangeTo" title={lang.to_time_column} editable={false} width={"80px"} cell={MyTimeCell} />
                    <GridColumn field="ProductCode" title={lang.product_code_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="TotalSpots" title={lang.total_spot_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="TotalSeconds" title={lang.total_fct_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="TotalAmount" title={lang.total_amount_column} editable={false} width={"100px"} cell={WrappedTextCell} />

                    {/* days of the month by looping */}
                    {days.map((day, index) => {
                        return (
                            <GridColumn key={index} field={`${day}.daySpots`} title={`${day}`} width={"120px"} headerCell={DateDayCell} cell={MyNumericCell} />
                        )
                    })}

                </Grid>
            </div>
        </div>

        {/* footer */}
        <div className='row mr-1 ml-1' style={{ border: "black solid 1px" }} >
            <div className='col-9' style={{ display: "flex" }}>
                <div style={{ marginLeft: "20px" }}><p style={{ margin: "0px", padding: "0px" }}>{lang.booked_spots_lable}</p><p style={campaignDetailValueStyle}> {bookedSpots}</p></div>
                <div style={{ marginLeft: "20px" }}><p style={{ margin: "0px", padding: "0px" }}>{lang.booked_seconds_label}</p><p style={campaignDetailValueStyle}> {bookedSeconds}</p></div>
                <div style={{ marginLeft: "20px" }}><p style={{ margin: "0px", padding: "0px" }}>{lang.booked_amount_label}</p><p style={campaignDetailValueStyle}>  {bookedAmount}</p></div>
            </div>
            <div className='col-3 text-right'>
                <div style={{ marginLeft: '40%', marginTop: "5px", textAlign: 'right' }}>
                    <p style={{ margin: '0px', paddingLeft: '6px', display: 'flex' }}>{status}</p>
                    <p style={{ margin: '0px', display: 'flex' }}><span style={{ width: '15px', height: '15px', margin: "0px", padding: "0px", backgroundColor: '#F55050', margin: '0px 5px' }}></span>{lang.already_scheduled_label}</p>
                    <p style={{ margin: '0px', display: 'flex' }}><span style={{ width: '15px', height: '15px', margin: "0px", padding: "0px", backgroundColor: '#C9F4AA', margin: '0px 5px' }}></span>{lang.not_scheduled_label}</p>
                </div>
            </div>
        </div>

        {openEditBookingPopup && <UpdateBookingLineNumberEditForm dataItem={editDataItem} selectedCampaign={selectedCampaign} onClose={() => setOpenEditBookingPopup(false)} days={days} onUpdateBookingLine={onUpdateBookingLine} editDisable={editDisable} />}

        {openMultiEditBookingPopup && <UpdateBookingLineNumberEditMultipleForm bookingsForMultiEdit={bookingsForMultiEdit.current} updateBookingsOnMultiEditSave={updateBookingsOnMultiEditSave} dataItem={editDataItem} selectedCampaign={selectedCampaign} onClose={() => setOpenMultiEditBookingPopup(false)} days={days} editDisable={editDisable} totalBookedSpots={bookedSpots} totalBookedSeconds={bookedSeconds} totalBookedAmount={bookedAmount} />}

        {showProgressBar.progressBarPopup && <div id='myProgressBar'>
        <div className='myProgressBarBox'>
          <ProgressBar
            label={(props) => {
              return <strong>{props.value}%</strong>;
            }}
            labelPlacement='center'
            progressStyle={{ backgroundColor: "green" }}
            value={progressData.progress}
            style={{ width: "360px" }}
          />
          <div className='message'>
            <p>{progressData.message}</p>
          </div>
          {showProgressBar.okButton && <button onClick={() => { setShowProgressBar({ ...showProgressBar, progressBarPopup: false }); }} style={{ width: "50px" }} className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-success text-white' >Ok</button>}
        </div>
        </div>}
    </>)
}

export default UpdateBookingLineNumber
