/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import * as API from "../../framework/API/api";
import { ENTITYNAME, MEDIACATEGORIES, MEDIACATEGORYTYPES } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import SaveButton from "../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { utility } from "../../framework/utility/utilityProvider";
import BossDialog from "../../components/BossDialog";

const DATA_ITEM_KEY = "SID";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const ContractCollectionSelection = (props) => {

  var page_take = props.page_take ?? 30;
  var wherestatement = props.wherestatement;
  var andWhereStatement = props.andWhereStatement;
  var hideSeriesSeason = props.hideSeriesSeason ?? false;
  var showAddMedia = props.showAddMedia ?? false;
  var isLongMediaCategory = props.isLongMediaCategory ?? true;
  var showMediaCategoryTypeDropDown = props.showMediaCategoryTypeDropDown ?? false;

  const navigate = useNavigate();
  const lang=useTranslation();
  const [gridData, setGridData] = useState([]);
  const [dataColumns,setDataColumns] = useState([
  {
    "name": "Title",
    "label": "Title",
    "sequence": 1,
    "required": true,
    "type": "input",
    "entityName": "MediaEpisode",
    "maxLength": 250,
    "format": null,
    "width": null
  },
  {
    "name": "AssetId",
    "label": "Asset Id",
    "sequence": 4,
    "required": true,
    "type": "input",
    "entityName": "MediaEpisode",
    "maxLength": 20,
    "format": null,
    "width": null
  },
  {
    "name": "ReferenceCode",
    "label": "Reference Code",
    "sequence": 4,
    "required": true,
    "type": "input",
    "entityName": "MediaEpisode",
    "maxLength": 20,
    "format": null,
    "width": null
  },
  {
    "name": "EpisodeTitle",
    "label": "Episode Title",
    "sequence": 4,
    "required": true,
    "type": "input",
    "entityName": "MediaEpisode",
    "maxLength": 20,
    "format": null,
    "width": null
  },
  


]);
  const [searchText,setSearchText] = useState('');
  const [mediaCategories, setMediaCategories]  = useState([]);
  const [mediaCategoryTypes, setMediaCategoryTypes] = useState([]);
  var [selectedMediaCategory, setSelectedMediaCategory] = useState(props?.mediaCategory ?? {});
  var [selectedMediaCategoryType, setSelectedMediaCategoryType] = useState(props?.mediaCategoryType ?? {});

  const [selectedState, setSelectedState] = useState({});
  const [count,setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  


  useEffect(() => {
    loaddata(searchText);
    loadcombo();
  }, []);

  const loadcombo = async () => {

    if(props.showMediaCategoryTypeDropDown){
      let res = await utility.getMediaCategroyTypeWithMediaCategory()
      setMediaCategoryTypes(res.filter((x) => !x.MediaCategory.isLong));

    } else {

      let mediaCategories = await API.getData(ENTITYNAME.MediaCategory, {query: ['isLong','=',isLongMediaCategory]});
  
      if(hideSeriesSeason){
        let filtered = mediaCategories.data.filter((x) => x.SID != MEDIACATEGORIES.Series && x.SID != MEDIACATEGORIES.Season)
        setMediaCategories(filtered);
        setSelectedMediaCategory(props?.mediaCategory ?? filtered[0])
      }else{
        setMediaCategories(mediaCategories.data);
        setSelectedMediaCategory(props?.mediaCategory ?? mediaCategories.data[0])
      }

    }

  }

  const getFilterQueryAndEntityNameForMediaCategory = () => {
    let obj  = { entityName : 'mediaepisode',wherestatement : ['MediaCategory.SID', '=', MEDIACATEGORIES.Program]}
    switch(selectedMediaCategory.SID){

      case MEDIACATEGORIES.Program: 
       obj.entityName = 'mediaepisode'
       obj.wherestatement = ['MediaCategory.SID', '=', MEDIACATEGORIES.Program];
      break;

      case MEDIACATEGORIES.Episode: 
       obj.entityName = 'mediaepisode'
       obj.wherestatement = ['MediaCategory.SID', '=', MEDIACATEGORIES.Episode];
      break;

      case MEDIACATEGORIES.Movie: 
      obj.entityName = 'mediaepisode'
      obj.wherestatement = ['MediaCategory.SID', '=', MEDIACATEGORIES.Movie];
      break;

      case MEDIACATEGORIES.Series: 
      obj.entityName = 'series'
      obj.wherestatement = ['MediaCategory.SID', '=', MEDIACATEGORIES.Series];
      break;

      case MEDIACATEGORIES.Season: 
      obj.entityName = 'season'
      obj.wherestatement = ['MediaCategory.SID', '=', MEDIACATEGORIES.Season];
      break;

      case MEDIACATEGORIES.Interstitial: 
      obj.entityName = 'mediaepisode'
      obj.wherestatement = ['MediaCategory.SID', '=', MEDIACATEGORIES.Interstitial];
      break;

      case MEDIACATEGORIES.Commercial: 
      obj.entityName = 'mediaepisode'
      obj.wherestatement = ['MediaCategory.SID', '=', MEDIACATEGORIES.Commercial];
      break;

      case MEDIACATEGORIES.Filler: 
      obj.entityName = 'mediaepisode'
      obj.wherestatement = ['MediaCategory.SID', '=', MEDIACATEGORIES.Filler];
      break;

      case MEDIACATEGORIES.Music: 
      obj.entityName = 'mediaepisode'
      obj.wherestatement = ['MediaCategory.SID', '=', MEDIACATEGORIES.Music];
      break;

    }
    return obj;
  }

  const getFilterQueryAndEntityNameForMediaCategoryType = () => {

    let obj  = { entityName : ENTITYNAME.MediaEpisode ,wherestatement : [['MediaCategory.isLong', '=', false],["MediaCategoryType.SID","=",selectedMediaCategoryType.SID]]}

    return obj;
  }

  const loaddata = async (searchText) => {
    try {
      setIsLoading(true);
      let filter = showMediaCategoryTypeDropDown ? getFilterQueryAndEntityNameForMediaCategoryType() : getFilterQueryAndEntityNameForMediaCategory()
      
      let json = await API.getEntitiesWithSearch(filter.entityName,{ query: filter.wherestatement }, dataColumns, searchText);

      setGridData(json.data);      
      setCount(json.data.length);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }

  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);
 
    },[selectedState]
  );

  const onHeaderSelectionChange = useCallback((event) => {

    if(props.mode == 'single'){
      toast.info(`${lang.select_any_one_collection_selection_error_message}` , {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
  
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
    
  }, []);

    

  //implementing search

  const onSearchEnter = (event) => 
  {
    if (event.key === "Enter") {
      // Cancel the default action, if needed
      event.preventDefault();
      onSearchClick();
    }
  }

  const handleServerSideSearch = (e) => {
    setSearchText(() => e.target.value);

    if(e.target.value == ''){
      loaddata('');
    }
  };

const onSearchClick = () => {

    if(searchText != ''){
      loaddata(searchText);
    }else{
      loaddata('');
    }

}


  const checkIfItemExist =(item) => {

    let isFound = false;
    let existingEntries = props.existingItems ?? [];
    console.log("checking duplicate")
    console.log(item.SID);
    console.log(props);
    if(props.checkDuplicate??false)
      {
            isFound = existingEntries.some(element => {
            console.log("props");
            console.log(props);
            if (element.SID === item.SID) {
              return true;
            }
        
            return false;
          });
      }
      console.log("isFound");
      console.log(isFound);
      return isFound;
    
  };

  //to sendDataList to RailsEditForm and to close the collection
  const onSelection = () => {

    let selectedDataList = [];
//loop for setting the selected obj in array
    gridData.map((obj) => {
      var key = obj.SID;
      //if true means checked
      if(selectedState[key])
      {
        // checking duplicate as well
        if(!checkIfItemExist(obj))
        {
          selectedDataList.push(obj);
        }        
      }      
    })

    if(selectedDataList.length != 0){
      props.setDataList(selectedDataList, selectedMediaCategory);
      props.closeForm();
    }else{
      toast.info(`${lang.please_select_first_contract_collection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  const onAddMedia = () => {
    console.log(selectedMediaCategory)
    navigate(`/home/MediaEpisode/${selectedMediaCategory.Description}/0`, { state : { quickEdit : false} })
  }

  const onChange = (e) => {
    selectedMediaCategory = e.target.value
    setSelectedMediaCategory(selectedMediaCategory)
    console.log(selectedMediaCategory)
    loaddata(searchText);
  }

  const onMediaCategoryTypeChange = (e) => {
    selectedMediaCategoryType = e.target.value
    setSelectedMediaCategoryType(selectedMediaCategoryType)
    console.log(selectedMediaCategoryType)
    loaddata(searchText);
  }

  const onRowDoubleClick = (event) => {
    const item = event.dataItem;

    if (!checkIfItemExist(item)) {
      props.setDataList([item], selectedMediaCategory);
      props.closeForm();
    } else {
      toast.info(`${lang.duplicate_item_selected_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  return (
    <BossDialog
            title={props.title ?? "ADD"}
            onClose={props.closeForm}
            width={props.width ?? "75vw"}>
      <div>
        <div className="row ml-1 mb-3" >
          <div className="col-5">
            <div className="row">
              <SaveButton title={lang.select_button_text} style={{backgroundColor: 'green',borderRadius : "4px"}} onClick={onSelection}></SaveButton>
              {showAddMedia && <ActionButton title={'Add Media'} name={'Add Media'} onClick={onAddMedia} />}
              <CancelButton onClick={props.closeForm}></CancelButton>
            </div>
          </div>
          <div className="col-3">
          <DropDownList
                style={{ backgroundColor:"white",height:"38px" }}
                data={showMediaCategoryTypeDropDown ? mediaCategoryTypes : mediaCategories}
                textField="Description"
                dataItemKey="SID"
                value={showMediaCategoryTypeDropDown ? selectedMediaCategoryType : selectedMediaCategory}
                onChange={showMediaCategoryTypeDropDown ? onMediaCategoryTypeChange : onChange}
            />
          </div>
          <div className="col-4" >
            <div className="input-group input-group mb-3" >
              <input type="text" className="form-control shadow-inset-2 pt-0 pb-0" id="searchInput" aria-label="type 2 or more letters" placeholder={lang.search_button_text} onKeyDown={onSearchEnter} onChange={handleServerSideSearch}/>
                  <button id="search" style={{border:"0px",backgroundColor:"white",height:"38px"}} onClick={onSearchClick}>
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i style={{margin:"4px 0px"}} className="fa fa-search"/>
                      </span>
                    </div>
                  </button>
            </div>
          </div>
        </div>
      </div>
      {isLoading ?
            <Loader height={"42vh"} /> :
      <div>
        <Grid
          data={gridData.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: props.mode ?? "multiple",
          }}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          onRowDoubleClick={onRowDoubleClick}
          style={{height:"42vh",wdith:"60vw"}}
        >
          <GridColumn
            field={SELECTED_FIELD}
            width="50px"
            headerSelectionValue={
              gridData.findIndex((item) => !selectedState[idGetter(item)]) === -1
            }
          />
          <GridColumn field="AssetId" title={lang.assetId_column} editable={false}/>
          <GridColumn field="Title" title={lang.title_column}  editable={false} />
          {isLongMediaCategory && <GridColumn field="EpisodeTitle" title={lang.episode_title_label} editable={false} />}
          <GridColumn field="Duration" title={lang.duration_column} cell={TimeCell} editable={false} />
          {isLongMediaCategory && <GridColumn field="ReferenceCode" title={lang.reference_code_column} editable={false} />}
          {!isLongMediaCategory &&<GridColumn field="MediaCategory.Description" title={lang.media_category_column} editable={false} />}
          {!isLongMediaCategory &&<GridColumn field="MediaCategoryType.Description" title={lang.media_category_type_column}  editable={false} />}
        </Grid>
      </div>}
      <div className="text-right mt-1">{`Total : ${count}`}</div>
    </BossDialog>
  );
};

export default ContractCollectionSelection;
