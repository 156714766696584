/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import * as API from "../../framework/API/api"
import { ApplicationConfigurationKeys, ENTITYNAME, MEDIACATEGORIES } from "../../framework/constant/constant";
import { RadioButton } from "@progress/kendo-react-inputs";
import moment from "moment";
import { TimeRangePicker } from "../../framework/forms/helpercomponents/timepicker/TimeRangePicker";
import { toast } from 'react-toastify';
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import BossDialog from "../../components/BossDialog";

function BookingEditForm(props) {
  const [channel, setChannel] = useState([]);
  const [timeZone, setTimeZone] = useState([]);
  const [campaignType, setCampaignType] = useState([]);
  const [orderType, setOrderType] = useState([]);
  const [dealType, setDealType] = useState([]);
  const [position, setPosition] = useState([]);
  const [spotType, setSpotType] = useState("PerDay");
  const [rateType, setRateType] = useState("Rate");
  const [isTRPEnabled, setIsTRPEnabled] = useState(false);
  const [allDay, setAllDay] = useState(true);
  const [allCommercials, setAllCommercials] = useState(true);
  const [distributeByPercentage, setDistributeByPercentage] = useState(false);

  let spotsPerTRP = utility.getApplicationConfigurationByKey(ApplicationConfigurationKeys.spotsPerTRP)

  var selectedCampaign = props.selectedCampaign ?? { PeriodFrom: '', PeriodTo: '' };
  selectedCampaign.Commercials = selectedCampaign.Commercials.map(obj => ({ ...obj, MaxDistributionCount: 0, DistributionPercentage: 0, IsSelected: true }));
  // console.log(selectedCampaign.Commercials);
  const bookingDateLimit = {
    From: selectedCampaign.PeriodFrom > new Date(props.campaignDays[0].slice(0, 10)).getTime() ? moment(new Date(selectedCampaign.PeriodFrom)).format("YYYY-MM-DD") : props.campaignDays[0].slice(0, 10),
    To: selectedCampaign.PeriodTo < new Date(props.campaignDays[props.campaignDays.length - 1].slice(0, 10)).getTime() ? moment(new Date(selectedCampaign.PeriodTo)).format("YYYY-MM-DD") : props.campaignDays[props.campaignDays.length - 1].slice(0, 10),
    FromTime: '00:00'
  }
  const selectedCommercialsRef = useRef(selectedCampaign.Commercials);
  const [selectedCommercials, _setSelectedCommercial] = useState(selectedCampaign.Commercials);
  const setSelectedCommercial = (data) => {
    selectedCommercialsRef.current = data;
    _setSelectedCommercial(data);
  }
  const [openProgramSelection, setOpenProgramSelection] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({});
  var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const lang = useTranslation();


  var lineNo = props.maxLineBookingNo;

  var selectedCommercialKey = {};
  if (selectedCampaign.Commercials.length != 0) {
    selectedCampaign.Commercials.map((obj) => {
      selectedCommercialKey = { ...selectedCommercialKey, [obj.Title]: true }
    })
  }
  var blankDataItem = {

    Channel: selectedCampaign.Channels[0] ?? channel.filter((obj) => obj.SID == 1)[0],
    CampaignType: selectedCampaign.CampaignType ?? campaignType.filter((obj) => obj.SID == 1)[0],
    DealType: selectedCampaign.DealType ?? dealType.filter((obj) => obj.SID == 1)[0],
    Position: position[0],
    FromDate: selectedCampaign.PeriodFrom > new Date(props.campaignDays[0].slice(0, 10)).getTime() ? moment(new Date(selectedCampaign.PeriodFrom)).format("YYYY-MM-DD") : props.campaignDays[0].slice(0, 10),
    ToDate: selectedCampaign.PeriodTo < new Date(props.campaignDays[props.campaignDays.length - 1].slice(0, 10)).getTime() ? moment(new Date(selectedCampaign.PeriodTo)).format("YYYY-MM-DD") : props.campaignDays[props.campaignDays.length - 1].slice(0, 10),
    FromTime: '00:00',
    ToTime: '23:59',
    Spot: '',
    SpotRate: '',
    Remark: '',
    EstimateCode: "",
    Count: 0,
    TimeZone: '',
    TotalAmount: 0,
    TotalSpots: 0,
    TotalFCT: '',

    //days
    Sun: true,
    Mon: true,
    Tue: true,
    Wed: true,
    Thu: true,
    Fri: true,
    Sat: true,

    //commercial key default true for checkbox
    ...selectedCommercialKey

  }
  const [dataItem, setDataItem] = useState(blankDataItem);
  const [timeRange, setTimeRange] = useState([]);
  var rowdata = {};
  // console.log(position.filter((obj) => obj.SID == 1)[0],);

  useEffect(() => {
    loadcombo();
  }, [dataItem]);

  const loadcombo = async () => {
    // var channel = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
    // var filterData = channel.data.filter((item) => item.SID == selectedCampaign.Channels[0].SID)
    // setChannel(filterData)
    var timeZone = await API.getDataLookup(ENTITYNAME.TimeZone, { sort: { Description: 1 } });
    setTimeZone(timeZone.data);
    var campaigntType = await API.getDataLookup(ENTITYNAME.CampaignType, { sort: { Description: 1 } });
    setCampaignType(campaigntType.data);
    var orderType = await API.getDataLookup(ENTITYNAME.OrderType, { sort: { Description: 1 } });
    setOrderType(orderType.data);
    var dealType = await API.getDataLookup(ENTITYNAME.DealType, { sort: { Description: 1 } });
    setDealType(dealType.data);
    var position = await API.getDataLookup(ENTITYNAME.Position, { sort: { Description: 1 } });
    setPosition(position.data);
    var timeRange = await API.getDataLookup(ENTITYNAME.TimeRange);
    setTimeRange(timeRange.data);

  }

  const isAllSelectedDays = (days) => {
    return days.Sun && days.Mon && days.Tue && days.Wed && days.Thu && days.Fri && days.Sat;
  }

  const onchange = (e) => {

    if (e.target.name == "FromTime" || e.target.name == "ToTime") {
      setDataItem({ ...dataItem, [e.target.name]: utility.validateShortTimeString(e.target.value) });
    } else if (e.target.name == "Spot" || e.target.name == "TRP") {
      if(isTRPEnabled && e.target.name == "TRP"){
        setDataItem({ ...dataItem, [e.target.name]: e.target.value, Spot: parseInt(e.target.value*spotsPerTRP) });
      } else {
        setDataItem({ ...dataItem, [e.target.name]: parseInt(e.target.value), TRP: parseFloat(e.target.value/spotsPerTRP) });
      }
    } else {
      var tempStore = { ...dataItem, [e.target.name]: e.target.value }
      setDataItem(tempStore);
      var isAllSelected = isAllSelectedDays(tempStore);
      setAllDay(isAllSelected);
    }

    // // TO UPDATE THE DISTRIBUTION PERCENTAGE VALUE OF ALL COMMERCIALS
    var newComm = selectedCommercials.map((x) => {
      var item = { ...x };
      if (x.SID.toString() == e.target.name) {
        item.DistributionPercentage = parseInt(e.target.value);
        console.log(x);

      }
      return item
    });
    setSelectedCommercial(newComm);

  }

  const onChangeCommercialCheckbox = (e) => {

    //on select all click
    if (e.target.name == "selectAllCommercial") {
      selectedCampaign.Commercials.map((obj) => {
        selectedCommercialKey = { ...selectedCommercialKey, [obj.Title]: !allCommercials }
      })
      setDataItem({ ...dataItem, ...selectedCommercialKey });
      //all selected by default and click to off select all make list empty
      allCommercials ? setSelectedCommercial([]) : setSelectedCommercial(selectedCampaign.Commercials);
      setAllCommercials(!allCommercials);
    } else {
      // need to resolve checkbox select bug
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });

      allCommercials && setSelectedCommercial([]);
      setAllCommercials(false);
      if (e.target.value) {
        var selectCommercial = selectedCampaign.Commercials.filter((obj) => obj.Title == e.target.name);
        setSelectedCommercial([...selectedCommercialsRef.current, ...selectCommercial]);

        var temp = [...selectedCommercials, ...selectCommercial]
        if (temp.length == selectedCampaign.Commercials.length) {
          setAllCommercials(true);
        }
      }
      else {
        var selectCommercial = selectedCommercials.filter((obj) => obj.Title != e.target.name);
        setSelectedCommercial(selectCommercial);
      }
    }

  }

  const onChangeCommercialDistributionPercentage = (e) => {
    setDistributeByPercentage(!distributeByPercentage)
  }

  const allDaySelect = (e) => {
    setAllDay(e.target.value);
    setDataItem({
      ...dataItem,
      Sun: e.target.value,
      Mon: e.target.value,
      Tue: e.target.value,
      Wed: e.target.value,
      Thu: e.target.value,
      Fri: e.target.value,
      Sat: e.target.value
    })
  }

  const isValid = () => {

    if (!utility.isValidDate(dataItem.FromDate)) {
      toast.error(`${lang.please_select_from_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!utility.isValidDate(dataItem.ToDate)) {
      toast.error(`${lang.please_select_to_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    //VALIDATION FOR FROM Date
    console.log(new Date(dataItem.FromDate).getTime())
    console.log(selectedCampaign.PeriodFrom)
    console.log(new Date(dataItem.FromDate).getTime() < selectedCampaign.PeriodFrom)

    if (new Date(dataItem.FromDate).getTime() < selectedCampaign.PeriodFrom) {
      return false;
    }

    //VALIDATION FOR TO Date
    if (new Date(dataItem.ToDate).getTime() > selectedCampaign.PeriodTo) {
      return false;
    }

    if (new Date(dataItem.FromDate).getTime() > new Date(dataItem.ToDate).getTime()) {
      toast.error(lang.to_date_should_be_greater_than_from_date_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.Spot == "" || dataItem.Spot == undefined) {
      toast.error(`${lang.please_enter_spot_booking_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Spot < 0) {
      toast.error(`${lang.spot_can_not_be_negative_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.SpotRate == "" || dataItem.SpotRate == undefined) {
      toast.error(`${lang.please_enter_rate_booking_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.SpotRate < 0) {
      toast.error(`${lang.rate_can_not_be_negative_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.Sun && !dataItem.Mon && !dataItem.Tue && !dataItem.Wed && !dataItem.Thu && !dataItem.Fri && !dataItem.Sat) {
      toast.error(`${lang.please_select_atleast_one_day_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (distributeByPercentage && utility.getSumOfAColumnValueFromList(selectedCommercials, 'DistributionPercentage') != 100) {
      toast.error(`${lang.ditribute_by_percentage_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (selectedCommercials.length == 0) {
      toast.error(`${lang.select_commercial_for_booking_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }

  const onDistrubuteEqualPercentage = (e) => {

    // selectedCommercialsRef.current ALREADY CONTAINS ONLY SEELCTED COMMERCIALS
    var newComm = selectedCommercialsRef.current.map((element) => {
      element.DistributionPercentage = parseInt(100 / selectedCommercialsRef.current.length);

      return element;
    });

    //ADDING REMAING PERCENTAGE
    var totalDistributionPercentage = utility.getSumOfAColumnValueFromList(newComm, 'DistributionPercentage');
    var remainingPercentage = 100 - totalDistributionPercentage;
    newComm[0].DistributionPercentage = newComm[0].DistributionPercentage + remainingPercentage;

    setSelectedCommercial(newComm);


  }

  const addSpotsPerDay = () => {

    var count = lineNo;

    var dtPeriodFrom = new Date(dataItem.FromDate)
    var dtPeriodTo = new Date(dataItem.ToDate)

    //to filter date only
    var dtfrom = dtPeriodFrom.getTime();
    var dtTo = dtPeriodTo.getTime();

    var monthNo = parseInt(month.indexOf(props.bookingDataItem.Month)) + 1;


    let addBookingItems = [];
    selectedCommercials.map((commercial) => {

      var TotalSpots = 0;
      var durationInSeconds = commercial.Duration / 1000;

      //collection add object
      rowdata = {
        UniqueLineNumber: selectedCampaign.CampaignNo + "-" + props.bookingDataItem.Year + "-" + monthNo + "-" + commercial.AssetId,
        LineNumber: count,
        Campaign: selectedCampaign,
        Channel: dataItem.Channel,
        Program: selectedProgram,
        Commercial: commercial,
        HouseNumber: commercial.AssetId,
        Duration: commercial.Duration,
        CampaignType: dataItem.CampaignType,
        DealType: dataItem.DealType,
        OrderType: dataItem.OrderType,
        Position: dataItem.Position ?? position[0],
        From: dataItem.FromDate,
        To: dataItem.ToDate,
        FromTime: dataItem.FromTime,
        ToTime: dataItem.ToTime,
        TimeZone: dataItem.TimeZone,
        Remark: dataItem.Remark,
        EstimateCode: dataItem.EstimateCode,
        SpotRate: rateType == 'Rate' ? dataItem.SpotRate : parseFloat((dataItem.SpotRate / 10 * durationInSeconds)).toFixed(2),
        Rate10Sec: rateType == 'Rate' ? parseFloat(((dataItem.SpotRate / durationInSeconds) * 10)).toFixed(2) : dataItem.SpotRate,
        IsRate10SecSelected: rateType == 'Rate' ? false : true,
      };

      var uniqDaysOfLine = [];
      //YYYY-MM-DD Monday
      props.campaignDays.map((date) => {
        if (dtfrom <= new Date(date.slice(0, 10)).getTime() && dtTo >= new Date(date.slice(0, 10)).getTime()) {

          days.map((day) => {
            if (date.includes(day)) {
              if (dataItem[day]) {
                rowdata[date] = parseInt(dataItem.Spot);
                if (!uniqDaysOfLine.includes(day)) {
                  uniqDaysOfLine.push(day)
                }
                TotalSpots = TotalSpots + parseInt(dataItem.Spot);
              } else {
                rowdata[date] = 0;
              }
            }
          })

        } else {
          rowdata[date] = 0 //hardcoded spot
        }

        rowdata["TotalSpot"] = TotalSpots;
        rowdata["TotalAmount"] = rowdata.SpotRate * TotalSpots;
        rowdata["TotalFCT"] = TotalSpots * rowdata.Duration / 1000;
        rowdata["DaysOfWeek"] = uniqDaysOfLine.map((x) => x.substring(0, 1)).join('/')

      })

      addBookingItems.push(rowdata)
      count = count + 1;

    });

    //addding data in form.
    if (addBookingItems.length > 0) props.setData(addBookingItems);

  }

  const getSelectedDaysSpotCountByPercentage = (fromDate, toDate) => {

    var remainingSpots = dataItem.Spot;
    var spotsPerDay = 1;

    var bookingDays = []
    for (let index = 0; index < days.length; index++) {
      const element = days[index];
      bookingDays.push({ DayId: index, DayName: element, IsSelected: false, SpotCount: 0 });
    }

    const bookingLessThanAWeek = (toDate.getTime() - fromDate.getTime()) / 86400000 < 7;

    if (bookingLessThanAWeek) {
      var dateToCheck = fromDate;
      while (dateToCheck.getTime() <= toDate.getTime()) {
        const dayName = days[dateToCheck.getDay()];
        bookingDays[dateToCheck.getDay()].IsSelected = true;

        dateToCheck = new Date(dateToCheck.getTime() + 86400000);
      }
    }
    else {
      bookingDays.forEach((i) => { i.IsSelected = true; });
    }


    selectedCommercials.forEach(element => {
      element.MaxDistributionCount = parseInt((dataItem.Spot * element.DistributionPercentage) / 100);
    });

    var selectedDays = getSelectedDays();

    var commercialIndex = 0;
    var isCommercialPlacementLeft = true;

    while (remainingSpots > 0 && isCommercialPlacementLeft) {

      selectedDays.forEach(element => {

        if (remainingSpots > 0 && bookingDays.some(x => x.IsSelected && x.DayId == element.DayId)) {

          var loopCount = 0;
          var isCommercialFound = false;

          while (loopCount <= selectedCommercials.length && !isCommercialFound) {

            if (selectedCommercials[commercialIndex].MaxDistributionCount > 0) {

              element.SpotCount = element.SpotCount + spotsPerDay;
              element.Commercials.push(selectedCommercials[commercialIndex]);
              selectedCommercials[commercialIndex].MaxDistributionCount = selectedCommercials[commercialIndex].MaxDistributionCount - 1;
              remainingSpots = remainingSpots - 1;
              isCommercialFound = true;
              commercialIndex = 0; //resetting


            }
            else commercialIndex = commercialIndex + 1;

            commercialIndex = commercialIndex > selectedCommercials.length - 1 ? 0 : commercialIndex;

            loopCount = loopCount + 1;

          }

          if (!isCommercialFound) isCommercialPlacementLeft = false;
        }

      });

    }


    if (remainingSpots > 0) {

      const selectedCommercialsLocal = selectedCommercials.sort((a, b) => a.DistributionPercentage - b.DistributionPercentage);
      commercialIndex = 0;

      while (remainingSpots > 0) // In a case 4 remaing spots left , but selected days are only sat and sun, So 2 spots were still left.
      {

        selectedDays.sort((a, b) => a.Commercials.length - b.Commercials.length).forEach(item => {

          if (remainingSpots > 0 && bookingDays.some(x => x.IsSelected && x.DayId == item.DayId)) // booking days so that if days are lesser
          {
            item.SpotCount = item.SpotCount + spotsPerDay;
            item.Commercials.push(selectedCommercialsLocal[commercialIndex]);
            remainingSpots = remainingSpots - spotsPerDay;

            commercialIndex = commercialIndex + 1;
            commercialIndex = commercialIndex > selectedCommercialsLocal.length - 1 ? 0 : commercialIndex;
          }
        });
      }
    }

    return selectedDays;
  }

  const getSelectedDaysSpotCount = (fromDate, toDate) => {

    var remainingSpots = dataItem.Spot;
    var spotsPerDay = 1;

    var bookingDays = []
    for (let index = 0; index < days.length; index++) {
      const element = days[index];
      bookingDays.push({ DayId: index, DayName: element, IsSelected: false, SpotCount: 0 });
    }

    const bookingLessThanAWeek = (toDate.getTime() - fromDate.getTime()) / 86400000 < 7;

    if (bookingLessThanAWeek) {
      var dateToCheck = fromDate;
      while (dateToCheck.getTime() <= toDate.getTime()) {
        const dayName = days[dateToCheck.getDay()];
        bookingDays[dateToCheck.getDay()].IsSelected = true;

        dateToCheck = new Date(dateToCheck.getTime() + 86400000);
      }
    }
    else {
      bookingDays.forEach((i) => { i.IsSelected = true; });
    }


    var selectedDays = getSelectedDays();

    while (remainingSpots > 0) {

      selectedDays.forEach(element => {

        if (remainingSpots > 0 && bookingDays.some(x => x.IsSelected && x.DayId == element.DayId)) {
          element.SpotCount = element.SpotCount + spotsPerDay;
          remainingSpots = remainingSpots - spotsPerDay;
        }

      });

    }

    return selectedDays;
  }

  const getSelectedDays = () => {

    var selectedDays = [];
    for (let index = 0; index < days.length; index++) {
      const day = days[index];
      if (dataItem[day]) selectedDays.push({ DayId: index, DayName: day, SpotCount: 0, IsSelected: true, Commercials: [] });

    }
    return selectedDays;

  }


  const addSpotsPerWeek = () => {

    var count = lineNo;

    var dtPeriodFrom = new Date(dataItem.FromDate)
    var dtPeriodTo = new Date(dataItem.ToDate)

    //to filter date only
    var dtfrom = dtPeriodFrom.getTime();
    var dtTo = dtPeriodTo.getTime();

    var selectedDays = getSelectedDays();

    var validDaySpots = distributeByPercentage ? getSelectedDaysSpotCountByPercentage(dtPeriodFrom, dtPeriodTo) : getSelectedDaysSpotCount(dtPeriodFrom, dtPeriodTo);

    let addBookingItems = [];
    selectedCommercials.map((commercial) => {

      var TotalSpots = 0;
      var monthNo = parseInt(month.indexOf(props.bookingDataItem.Month)) + 1;

      var durationInSeconds = commercial.Duration / 1000;

      //collection add object
      rowdata = {
        UniqueLineNumber: selectedCampaign.CampaignNo + "-" + props.bookingDataItem.Year + "-" + monthNo + "-" + commercial.AssetId,
        LineNumber: count,
        Campaign: selectedCampaign,
        Channel: dataItem.Channel,
        Program: selectedProgram,
        Commercial: commercial,
        HouseNumber: commercial.AssetId,
        Duration: commercial.Duration,
        CampaignType: dataItem.CampaignType,
        DealType: dataItem.DealType,
        OrderType: dataItem.OrderType,
        Position: dataItem.Position ?? position[0],
        From: dataItem.FromDate,
        To: dataItem.ToDate,
        FromTime: dataItem.FromTime,
        ToTime: dataItem.ToTime,
        TimeZone: dataItem.TimeZone,
        Remark: dataItem.Remark,
        EstimateCode: dataItem.EstimateCode,
        SpotRate: rateType == 'Rate' ? dataItem.SpotRate : parseFloat((dataItem.SpotRate / 10 * durationInSeconds)).toFixed(2),
        Rate10Sec: rateType == 'Rate' ? parseFloat(((dataItem.SpotRate / durationInSeconds) * 10)).toFixed(2) : dataItem.SpotRate,
        IsRate10SecSelected: rateType == 'Rate' ? false : true,
      };
      //YYYY-MM-DD Monday
      props.campaignDays.map((date) => {

        // if withing selected days
        if (dtfrom <= new Date(date.slice(0, 10)).getTime() && dtTo >= new Date(date.slice(0, 10)).getTime()) {

          const dayName = date.split(' ')[1];
          const canAddSpot = selectedDays.some(x => x.DayName == dayName);

          if (canAddSpot) {

            var weekDayViewModel = validDaySpots.find(param => param.DayName == dayName);
            console.log(weekDayViewModel.Commercials);
            console.log(commercial);
            var spotsPerDay = weekDayViewModel != null ? (distributeByPercentage ? weekDayViewModel.Commercials.filter(x => x._id.toString() == commercial._id.toString()).length : weekDayViewModel.SpotCount) : 0;
            rowdata[date] = spotsPerDay ?? 0;
            TotalSpots = TotalSpots + parseInt(spotsPerDay);
          } else {
            rowdata[date] = 0
          }
        }
        else {
          rowdata[date] = 0 //hardcoded spot
        }

        rowdata["TotalSpot"] = TotalSpots;
        rowdata["TotalAmount"] = rowdata.SpotRate * TotalSpots;
        rowdata["TotalFCT"] = TotalSpots * rowdata.Duration / 1000;
        rowdata["DaysOfWeek"] = selectedDays.map((x) => x.DayName.substring(0, 1)).join('/')

      })

      addBookingItems.push(rowdata)
      count = count + 1;

    });

    //addding data in form.
    if (addBookingItems.length > 0) props.setData(addBookingItems);

  }

  const onSubmit = (e) => {

    if (!isValid()) {
      return;
    }

    if (spotType == 'PerWeek') {
      addSpotsPerWeek();
    }
    else {
      addSpotsPerDay();
    }


    if (e.target.name == 'Add') {
      props.cancelEdit();
      setDataItem(blankDataItem);
    }

  }

  const onSelectProgram = () => {
    setOpenProgramSelection(true)
  }

  const onSetSelectedProgram = (dataList) => {
    setSelectedProgram(dataList[0]);
  }

  return (
    <>
      {<BossDialog
        title={lang.add_booking_dialog_header}
        onClose={props.cancelEdit}
        width={750}
      >
        <Form
          render={(formRenderProps) => (
            <FormElement>

              <div style={{ display: 'flex' }}>

                <div>
                  <button
                    onClick={(e) => { onSubmit(e) }}
                    type={"submit"}
                    style={{ width: "60px" }}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    name="Add"
                  >
                    {lang.add_button_text}
                  </button>
                </div>
                <div className="ml-2" style={{ padding: "0px" }}>
                  <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-success"
                    onClick={(e) => onSubmit(e)}
                    name="AddMultiple"
                  >
                    {lang.add_multiple_button_text}
                  </button>
                </div>
                <div className="ml-2" style={{ padding: "0px" }}>
                  <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-error"
                    onClick={props.cancelEdit}
                  >
                    {lang.cancel_button_text}
                  </button>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-6">
                  <label htmlFor="">{lang.channel_label} *</label>
                  <DropDownList
                    style={{
                      backgroundColor: "white",
                    }}
                    data={selectedCampaign.Channels}
                    name="Channel"
                    textField="FullChannelName"
                    dataItemKey="_id"
                    value={dataItem.Channel}
                    onChange={onchange}
                    validator={(value) => value ? "" : "Please select the value"}
                  />
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="">{lang.from_date_label} *</label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      name="FromDate"
                      value={dataItem.FromDate}
                      onChange={onchange}
                      min={bookingDateLimit.From}
                      max={bookingDateLimit.To}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="">{lang.to_date_label} *</label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      name="ToDate"
                      value={dataItem.ToDate}
                      onChange={(e) => onchange(e)}
                      min={bookingDateLimit.From}
                      max={bookingDateLimit.To}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-3">
                  <label htmlFor="">{lang.type_label} *</label>
                  <DropDownList
                    style={{
                      backgroundColor: "white",
                    }}
                    data={campaignType}
                    name="CampaignType"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem.CampaignType}
                    onChange={onchange}
                    validator={(value) => value ? "" : "Please select the value"}
                  />
                </div>

                <div className="col-3">
                  <label htmlFor="">{lang.deal_type_label} *</label>
                  <DropDownList
                    style={{
                      backgroundColor: "white",
                    }}
                    data={dealType}
                    name="DealType"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem.DealType}
                    onChange={onchange}
                    validator={(value) => value ? "" : "Please select the value"}
                  />
                </div>

                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="FromTime">{lang.from_time_label} *</label>
                    <TimeRangePicker
                      name="FromTime"
                      value={dataItem.FromTime}
                      onChange={(e) => onchange(e)}
                    />
                  </div>
                </div>
                <div className="col-3" >
                  <div className="form-group">
                    <label htmlFor="ToTime">{lang.to_time_label} *</label>
                    <TimeRangePicker
                      name="ToTime"
                      value={dataItem.ToTime}
                      onChange={(e) => onchange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">

                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="">
                      <RadioButton
                        name="Spot"
                        value="Spot"
                        checked={!isTRPEnabled}
                        label={lang.spot_label}
                        onChange={(e) => { setIsTRPEnabled(false) }}
                      />
                      <RadioButton
                        style={{ marginLeft: "15px" }}
                        name="TRP"
                        value="TRP"
                        checked={isTRPEnabled}
                        label={lang.trp_label}
                        onChange={(e) => { setIsTRPEnabled(true) }}
                      />
                    </label>

                    {!isTRPEnabled ? <input
                      type="number"
                      className="form-control form-control-sm"
                      name="Spot"
                      value={dataItem.Spot}
                      min={0}
                      onChange={(e) => onchange(e)}
                    /> :
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      name="TRP"
                      value={dataItem.TRP}
                      min={0}
                      step={0.001}
                      onChange={(e) => onchange(e)}
                    />}
                  </div>
                </div>

                <div className="col-3" style={{ marginTop: "22px" }}>
                  <RadioButton
                    name="SpotType"
                    value="PerDay"
                    checked={spotType === "PerDay"}
                    label={lang.perday_label}
                    onChange={(e) => { onchange(e); setSpotType(e.value) }}
                  />
                  <RadioButton
                    style={{ marginLeft: "5px" }}
                    name="SpotType"
                    value="PerWeek"
                    checked={spotType === "PerWeek"}
                    label={lang.perweek_label}
                    onChange={(e) => { onchange(e); setSpotType(e.value) }}
                  />
                </div>

                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="">{lang.rate_label} *</label>
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      name="SpotRate"
                      value={dataItem.SpotRate}
                      onChange={(e) => onchange(e)}
                    />
                  </div>
                </div>

                <div className="col-3" style={{ marginTop: "22px", padding: "0" }}>
                  <RadioButton
                    name="RateType"
                    value="Rate"
                    checked={rateType === "Rate"}
                    label={lang.rate_label}
                    onChange={(e) => { onchange(e); setRateType(e.value) }}
                  />
                  <RadioButton
                    style={{ marginLeft: "5px" }}
                    name="RateType"
                    value="Rate10Sec"
                    checked={rateType === "Rate10Sec"}
                    label={lang.rate10sec_label}
                    onChange={(e) => { onchange(e); setRateType(e.value) }}
                  />
                </div>

              </div>

              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="TabView">{lang.program_label}</label>
                    <input name="Title" type="text" className="form-control form-control-sm" value={selectedProgram.Title} onChange={() => ""} disabled={true} />
                  </div>
                </div>

                <div className="col-2 ">
                  <ActionButton style={{ marginTop: "20px", height: "30px" }} btnColor={'success'} title={lang.select_button_tooltip} name={"..."} onClick={onSelectProgram} />
                </div>

                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="">{lang.estimate_code_label}</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="EstimateCode"
                      value={dataItem.EstimateCode}
                      onChange={onchange}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="">{lang.remark_label}</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="Remark"
                      value={dataItem.Remark}
                      onChange={(e) => onchange(e)}

                    />
                  </div>
                </div>


              </div>

              <div className="row">
                <div className="col-2">
                  <label htmlFor="">{lang.position_label} *</label>
                  <DropDownList
                    style={{
                      backgroundColor: "white",
                    }}
                    data={position}
                    name="Position"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem.Position}
                    defaultValue={position[0]}
                    onChange={onchange}
                    validator={(value) => value ? "" : "Please select the value"}
                  />
                </div>
                <div className="col-2">
                  <label htmlFor="">{lang.count_label}</label>
                  <input name="Count" type="number" className="form-control form-control-sm" value={dataItem.Count} onChange={onchange} required />
                </div>

                <div className="col-8" style={{ marginTop: "20px", textAlign: "right" }}>

                  <Checkbox label={lang.select_all_label} value={allDay} onChange={allDaySelect} />
                  <div style={{ border: "1px dotted", display: "inline", padding: "5px", marginLeft: "5px" }}>
                    <div style={{ margin: '0px 4px', display: "inline" }} >
                      <Checkbox
                        name={"Sun"}
                        label={lang.sun_label}
                        onChange={onchange}
                        value={allDay && dataItem.Sun ? allDay : dataItem.Sun}
                      />
                    </div>
                    <div style={{ margin: '0px 4px', display: "inline" }} >
                      <Checkbox
                        name={"Mon"}
                        label={lang.mon_label}
                        onChange={onchange}
                        value={allDay && dataItem.Mon ? allDay : dataItem.Mon}
                      />
                    </div>
                    <div style={{ margin: '0px 4px', display: "inline" }} >
                      <Checkbox
                        name={"Tue"}
                        label={lang.tue_label}
                        onChange={onchange}
                        value={allDay && dataItem.Tue ? allDay : dataItem.Tue}
                      />
                    </div>
                    <div style={{ margin: '0px 4px', display: "inline" }} >
                      <Checkbox
                        name={"Wed"}
                        label={lang.wed_label}
                        onChange={onchange}
                        value={allDay && dataItem.Wed ? allDay : dataItem.Wed}
                      />
                    </div>
                    <div style={{ margin: '0px 4px', display: "inline" }} >
                      <Checkbox
                        name={"Thu"}
                        label={lang.thu_label}
                        onChange={onchange}
                        value={allDay && dataItem.Thu ? allDay : dataItem.Thu}
                      />
                    </div>
                    <div style={{ margin: '0px 4px', display: "inline" }} >
                      <Checkbox
                        name={"Fri"}
                        label={lang.fri_label}
                        onChange={onchange}
                        value={allDay && dataItem.Fri ? allDay : dataItem.Fri}
                      />
                    </div>
                    <div style={{ margin: '0px 4px', display: "inline" }} >
                      <Checkbox
                        name={"Sat"}
                        label={lang.sat_label}
                        onChange={onchange}
                        value={allDay && dataItem.Sat ? allDay : dataItem.Sat}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-2">
                  <Checkbox name={"selectAllCommercial"} checked={allCommercials} value={allCommercials} label={lang.select_all_label} onChange={onChangeCommercialCheckbox} />
                </div>
                <div className="col-3">
                  {spotType == 'PerWeek' &&
                    <Checkbox name={"distributeByPercentage"} checked={distributeByPercentage} value={distributeByPercentage} label={lang.ditribute_by_percentage_label} onChange={onChangeCommercialDistributionPercentage} />
                  }

                </div>
                <div className="col-7" style={{ textAlign: "right" }}>
                  {spotType == 'PerWeek' && distributeByPercentage &&
                    <button
                      onClick={(e) => { onDistrubuteEqualPercentage(e) }}
                      type={"button"}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-warning"
                      name="DistributeSelectedEqually"
                    >
                      {lang.distribute_selected_equal_lable}
                    </button>
                  }

                </div>
              </div>

              <div style={{ borderStyle: "ridge", padding: "5px", marginTop: "8px", height: '25vh', overflow: 'auto' }}>
                {selectedCampaign.Commercials.map((obj, index) => {

                  var temp = selectedCommercialsRef.current.length > 0 ? selectedCommercialsRef.current.find((x) => x.SID == obj.SID) : { DistributionPercentage: 0 };

                  return (<div key={index} className="row" style={{ display: "flex", paddingBottom: "5px" }}>
                    <div className="col-9">
                      <Checkbox
                        name={obj.Title}
                        label={obj.Title}
                        onChange={onChangeCommercialCheckbox}
                        value={dataItem[obj.Title]}
                      />
                    </div>
                    {distributeByPercentage &&

                      <div className="col-3">
                        <div className="row">
                          <div className="col-6">
                            <input
                              disabled={temp ? false : true}
                              type="number"
                              className="form-control form-control-sm"
                              name={`${obj.SID}`}
                              value={temp ? temp.DistributionPercentage : 0}
                              onChange={onchange}
                            />
                          </div>

                          <div className="col-1">
                            <span>%</span>
                          </div>
                        </div>
                      </div>
                    }


                  </div>
                  )
                })}

              </div>

            </FormElement>
          )} />
      </BossDialog>}
      {openProgramSelection && <CollectionSelection addButtonTitle={lang.select_button_text} title={lang.select_program_booking_collection_header} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setOpenProgramSelection(!openProgramSelection)} setDataList={(dataList) => onSetSelectedProgram(dataList)} wherestatement={['MediaCategory.SID', '=', MEDIACATEGORIES.Program]} mode={'single'} width={"50vw"} height={"60vh"} />}
    </>

  )
}

export default BookingEditForm;
